<template>
  <v-layout column class="datatable" :class="{'hide-separator': !separator}">
    <v-data-table
        :mobile-breakpoint="0"
        :footer-props="footerProps"
        :headers="headers"
        :items="items"
        :page.sync="pagination.page"
        :server-items-length.sync="pagination.totalItems"
        :items-per-page.sync="pagination.rowsPerPage"
        :sort-by.sync="pagination.sortBy"
        :sort-desc.sync="pagination.descending"
        :hide-default-footer="hideFooter"
        v-bind="$attrs"
        :show-select="showSelect"
        :item-key="itemKey"
        v-model="selected"
        :options.sync="options"
        :multi-sort="false"
        :must-sort="true"
        :force-select-all="forceSelectAll">
      <!-- Se o componente pai declarou o slot 'items' -->
      <template v-if="$scopedSlots.items && !loading" v-slot:item="{item}">
        <tr>
          <!-- Slot do checkbox de cada row -->
          <td v-if="showSelect">
            <v-row class="fill-height" justify="center" align="center">
              <v-checkbox
                  color="accent"
                  :value="item"
                  v-model="selected"
                  hide-details="false" />
            </v-row>
          </td>
          <!-- Slot dos dados de cada row <td> {{texto}} </td> -->
          <slot name="items" v-bind="item" />
        </tr>
      </template>

      <!-- Senão, cria slots dinâmicos baseados nos headers -->
      <template v-else v-for="h in headers"
                v-slot:[`item.${h.value}`]="{item}">
        <slot :name="`item.${h.value}`" v-bind="item" />
      </template>

      <!-- TOTAL-->
      <!-- Atenção: As "keys", precisam estar ordenadas na ordem de que ficará na tela para o calculo funcionar. -->
      <tr v-if="showTotal && items.length" slot="body.append" class="font-weight-bold">
        <td v-for="key in Object.keys(items[0])" :key="key">
          {{ key === 'dia' ? 'Total do período' : items.map((item) => item[key]).reduce((a,b) => a + b) }}
        </td>
      </tr>

      <v-layout class="carregando justify-center align-center" slot="footer" v-show="loading">
        <v-progress-circular class="ma-2" color="primary" :size="18" :width="2"
                             indeterminate />
        Carregando...
      </v-layout>
    </v-data-table>
    <div class="overlay" v-show="loading"></div>
  </v-layout>
</template>

<script>
  import { debounce } from 'lodash'

  export default {
    props: {
      url: String,
      params: Object,
      headers: Array,
      filter: Object,
      itemKey: String,
      showSelect: Boolean,
      separator: Boolean,
      hideFooter: Boolean,
      silent: Boolean,
      showTotal: Boolean,
      forceSelectAll: {
        type: Boolean,
        default: false
      }
    },
    data: () => ({
      loading: false,
      options: {},
      pagination: {
        page: 1,
        totalItems: 0,
        rowsPerPage: 12,
        sortBy: '',
        descending: false
      },
      items: [],
      selected: []
    }),
    computed: {
      footerProps: () => ({
        'items-per-page-options': [ 12, 25, 50 ]
      })
    },
    watch: {
      options: {
        handler () {
          this.reload()
        },
        deep: true
      },
      filter () {
        this.pagination.page = 1
        this.reload()
      },
      selected () {
        this.$emit('input', this.selected)
      },
      params: {
        handler () {
          this.reload()
        },
        deep: true
      },
      url () {
        this.reload()
      }
    },
    mounted () {
      this.pagination = { ...this.pagination, ...this.params.pagination }
      if (this.showSelect && !this.itemKey) {
        console.error('Prop item-key is required for select-all')
      }
    },
    methods: {
      // debounce evita varias requisicoes seguidas
      reload: debounce(function () {
        this.$http.cancel(this._uid)

        this.loading = true

        return this.$http.get(this.url, {
          requestId: this._uid,
          params: {
            ...this.params,
            pagination: this.pagination,
            filter: this.filter
          },
          options: { silent: this.silent }
        }).then((res) => {
          this.selected = []
          this.items = []
          this.$nextTick(() => {
            this.items = res.data.items
            if (this.forceSelectAll) {
              this.selected = this.items
            }
            this.pagination.totalItems = res.data.total
            this.$emit('totalItems', this.pagination.totalItems)
          })
        }).catch(() => {
          // cancel
        }).finally(() => (
          this.loading = false
        ))
      }, 10)
    }
  }
</script>

<style lang="scss" scoped>
    .datatable {
        position: relative;
        z-index: 1;
    }

    .carregando {
        position: absolute;
        bottom: 15px;
        z-index: 3;
    }

    .overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        user-select: none;
        background: #ffffff55;
    }
</style>

<style lang="scss">
    .v-simple-checkbox {
        .mdi-minus-box, .mdi-checkbox-marked {
            color: var(--accent);
        }
    }

    .datatable {
        .v-input--checkbox {
            &.v-input--selection-controls,
            .v-input--selection-controls__input {
                margin-top: 0;
                margin-right: 0;
                padding-top: 0.5px;
            }
        }

        &.hide-separator tbody tr {
            &:hover {
                background: none !important;
            }

            &:not(:last-child) {
                border-bottom: none;
                border-top: none;
            }
        }

        // remove o efeito de hover
        .v-input--selection-controls__input {
            .v-input--selection-controls__ripple:before {
                display: none;
            }
        }

        .v-ripple__animation {
            background: var(--accent);
        }
    }
</style>
