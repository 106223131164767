import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from 'vuetify/es5/locale/pt'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  lang: {
    locales: { pt },
    current: 'pt'
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0e6c3c',
        secondary: '#6C9CEA',
        accent: '#1C56B3',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        neutral: '#f5f5f5',
        lightgray: '#efefef',
        gray: '#d5d5d5',
        darkgray: '#a5a5a5',
        dark: '#707070'
      }
    }
  }
})
