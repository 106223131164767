<template>
  <v-app>
    <router-view />
    <Loading v-show="loading" />
    <Dialog :value="alert.visible" @input="alertHide" :options="alert.options" />
  </v-app>
</template>

<script>

  import { mapMutations, mapState } from 'vuex'
  import Loading from './components/Loading'

  export default {
    components: { Loading },
    computed: {
      ...mapState(['loading', 'alert'])
    },
    beforeCreate () {
      const administradora = this.$session.get('administradora')

      if (!administradora.cor_primaria) {
        administradora.cor_primaria = '#979c99'
      }

      if (!administradora.cor_secundaria) {
        administradora.cor_secundaria = '#232323'
      }

      this.$vuetify.theme.themes.light.primary = administradora.cor_primaria
      this.$vuetify.theme.themes.light.secondary = administradora.cor_secundaria
    },
    methods: {
      ...mapMutations(['alertHide'])
    }
  }
</script>
