<template>
  <div>
    <h1>API Documentation</h1>
    <swagger-ui :spec="swaggerSpec" :deep-linking="true"></swagger-ui>
  </div>
</template>

<script>
  import SwaggerUI from 'swagger-ui'
  import 'swagger-ui/dist/swagger-ui.css'

  export default {
    name: 'SwaggerDoc',
    data () {
      return {
        swaggerSpec: {
          swagger: '2.0',
          info: {
            title: 'API Assinatura Consórcio',
            version: '1.0.2'
          },
          paths: {
            '/login': {
              post: {
                tags: ['Login'],
                summary: 'Login',
                description: 'Login',
                operationId: 'login',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'login',
                    description: 'Login',
                    required: true
                  },
                  {
                    in: 'body',
                    name: 'senha',
                    description: 'Senha',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/assinatura': {
              post: {
                tags: ['Contrato'],
                summary: 'Cria um contrato',
                description: 'Cria um contrato',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'cpf',
                    in: 'body',
                    description: 'Cpf do Signatario',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'dt_nascimento',
                    in: 'body',
                    description: 'Data de nascimento do Signatario',
                    required: true,
                    type: 'date'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/reenviaremailfinal/{hash_contrato}': {
              post: {
                tags: ['Contrato'],
                summary: 'Reenvia o email final do contrato',
                description: 'Reenvia o email final do contrato',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'hash_contrato',
                    in: 'query',
                    description: 'Hash do contrato',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/cancelarcontrato/{hash_contrato}': {
              post: {
                tags: ['Contrato'],
                summary: 'Cancela o contrato',
                description: 'Cancela o contrato',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'hash_contrato',
                    in: 'query',
                    description: 'Hash do contrato',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/visualizarpdf/{hash_contrato}': {
              post: {
                tags: ['Contrato'],
                summary: 'Visualiza o pdf do contrato assinado',
                description: 'Visualiza o pdf do contrato assinado',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'hash_contrato',
                    in: 'query',
                    description: 'Hahs do contrato',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contratos/status': {
              get: {
                tags: ['Contrato'],
                summary: 'Retora o status dos contratos',
                description: 'Retora o status dos contratos',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'id',
                    in: 'body',
                    description: 'Array de ids de contratos',
                    required: true,
                    type: 'array'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/usuario/{id_usuario}/contratos/{id_documento}/reenvio': {
              get: {
                tags: ['Contrato'],
                summary: 'Reenvia o email de assinatura do contrato',
                description: 'Reenvia o email de assinatura do contrato',
                operationId: 'reenviarEmailContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'id_usuario',
                    in: 'query',
                    description: 'Id do usuário',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'id_documento',
                    in: 'query',
                    description: 'Id do contrato',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            }
          }
        }
      }
    },
    mounted () {
      SwaggerUI({
        dom_id: 'swagger-ui',
        spec: this.swaggerSpec
      })
      this.validate()
    },
    methods: {
      validate () {
        // prompt to password
        var password = prompt('Digite a senha para acessar a documentação', '')
        if (password === 'newm@2023') {
          // do nothing
        } else {
          // hide all content
          document.body.innerHTML = ''
          window.location.reload()
        }
      }
    }
  }
</script>

<style scoped>
h1 {
  text-align: center;
}
</style>
