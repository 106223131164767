<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Aditivos contratuais</h1>
          </v-flex>
        </v-layout>
      </v-flex>
      <!--  BUTTONS  -->
      <v-row class="mt-5" justify="end">
        <v-btn class="ma-2 home-btn" dark color="primary" :to="{name:'cadastrar_aditivo_contratual'}">
          <v-icon>mdi-plus</v-icon>
          <p>Adicionar aditivo</p>
        </v-btn>
      </v-row>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout mt-3 class="row">
        <v-layout column class="card table-home">
          <v-flex>
            <v-menu content-class="menu-card mt-1 app-content"
                    min-width="110px"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    v-model="popupFiltro">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" text class="v-btn filtrar" color="primary" @click="popupFiltro = true">
                  <v-icon left>icon-filter</v-icon>
                  Filtrar
                </v-btn>
              </template>
              <v-container class="filtro-div">
                <v-flex>
                  <v-row>
                    <v-col>
                      <label class="label-input">Nome do aditivo</label>
                      <v-text-field v-model="filtroDataTable.nome_aditivo"
                                    color="primary"
                                    placeholder="Nome do aditivo"
                                    dense
                                    outlined />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col>
                      <label class="label-input">Código do grupo</label>
                      <v-text-field v-model="filtroDataTable.cd_grupo"
                                    color="primary"
                                    placeholder="Código do grupo"
                                    dense
                                    outlined />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col>
                      <label class="label-input">Código do plano de venda</label>
                      <v-text-field v-model="filtroDataTable.cd_plano"
                                    placeholder="Código do plano de venda"
                                    dense
                                    outlined />
                    </v-col>
                  </v-row>
                  <v-row class="mt-n5">
                    <v-col>
                      <label class="label-input">Status do aditivo</label>
                      <v-radio-group row v-model="filtroDataTable.ativo">
                        <v-radio label="Ambos" :value="null"></v-radio>
                        <v-radio label="Ativo" :value="true"></v-radio>
                        <v-radio label="Inativo" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-flex>
                <v-flex mt-2 class="d-flex justify-center">
                  <v-btn color="primary" outlined class="v-btn btn-filtar mr-2" @click="limpar">Limpar</v-btn>
                  <v-btn color="primary" class="v-btn btn-filtar" @click="filtrar">Filtrar</v-btn>
                </v-flex>
              </v-container>
            </v-menu>
          </v-flex>
          <DataTable mt-2 ref="datatable" :url="url"
                     class="pt-2 data-table-overlow"
                     :headers="headers"
                     :params="params"
                     :filter="filtro"
                     :silent="true">
            <template v-slot:items="item">
              <td>{{ item.nome_aditivo }}</td>
              <td>
                <div class="d-inline" v-for="(grupo, i) in item.grupos" :key="grupo.id_aditivo_grupo">
                  <div v-if="i < 2" class="grupos-planos">
                    {{ grupo.cd_grupo }}
                  </div>
                  <div v-if="i === 2">
                    <v-btn class="v-btn" style="margin-top: 3px" x-small rounded outlined @click="abrirDialog(item.grupos, 'Grupos')">
                      <span class="mt-1" style="font-weight: bold;font-size: 10px;">
                        {{ '+' + (item.grupos.length - 2) + ' ver todos' }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-inline" v-for="(plano, i) in item.planos" :key="plano.id_aditivo_plano">
                  <div v-if="i < 2" class="grupos-planos">
                    {{ plano.cd_plano }}
                  </div>
                  <div v-if="i === 2">
                    <v-btn class="v-btn" style="margin-top: 3px" x-small rounded outlined @click="abrirDialog(item.planos, 'Planos')">
                      <span class="mt-1" style="font-weight: bold;font-size: 10px;">
                        {{ '+' + (item.planos.length - 2) + ' ver todos' }}
                      </span>
                    </v-btn>
                  </div>
                </div>
              </td>
              <td style="width: 1px">
                <v-flex>
                  <v-chip class="mt-1 mb-1 d-flex justify-center short align-content-center" :color="getAtivo(item.ativo).color" :input-value="true" outlined>
                    <span :class="getAtivo(item.ativo).id">
                      {{ getAtivo(item.ativo).text }}
                    </span>
                  </v-chip>
                </v-flex>
              </td>
              <td>
                <!-- MENU DE CONTEXTO -->
                <v-flex>
                  <v-menu origin="center center"
                          top
                          left
                          :offset-x="false"
                          :offset-y="true"
                          class="transparent"
                          transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-list" elevation="0" color="transparent" v-bind="attrs" v-on="on">
                        ...
                      </v-btn>
                    </template>
                    <v-list class="tooltip menu-balloon">
                      <div>
                        <v-list-item @click="alterarStatus(item)">
                          <v-flex class="d-flex justify-center">{{ item.ativo ? 'Desativar aditivo' : 'Ativar aditivo' }}</v-flex>
                        </v-list-item>
                      </div>
                      <v-divider style="border-color:white !important"></v-divider>
                      <div>
                        <v-list-item @click="remover(item)">
                          <v-flex class="d-flex justify-center">Apagar</v-flex>
                        </v-list-item>
                      </div>
                      <v-divider style="border-color:white !important"></v-divider>
                      <div>
                        <v-list-item @click="editar(item)">
                          <v-flex class="d-flex justify-center">Editar</v-flex>
                        </v-list-item>
                      </div>
                      <v-divider style="border-color:white !important"></v-divider>
                      <div>
                        <v-list-item @click="visualizar(item)">
                          <v-flex class="d-flex justify-center">Visualizar documento</v-flex>
                        </v-list-item>
                      </div>
                    </v-list>
                    <div class="arrow-down-lil"></div>
                  </v-menu>
                </v-flex>
              </td>
            </template>
          </DataTable>
        </v-layout>
      </v-layout>
    </v-layout>
    <v-dialog v-model="dialog" width="400" :scrollable="true" content-class="app-content" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title class="text-h5">
          <v-flex class="d-flex">
            <v-flex>{{ dialogData.titulo }}</v-flex>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="dialog = false">
                  <v-icon>fa fa-times</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-flex mt-2 mb-4>
            {{ dialogData.items.join(', ') }}
          </v-flex>
          <v-flex>
            <v-flex>
              <v-btn x-large color="primary" class="btn-default white--text" @click.stop="dialog = false">Ok</v-btn>
            </v-flex>
          </v-flex>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import DataTable from '../../components/DataTable'
  import Rules from '@/plugins/validation'
  import Filters from '@/plugins/filters'
  import { alert, error } from '../../plugins/helpers'

  export default {
    name: 'AditivosContratuais',
    components: { DataTable },
    data: () => ({
      url: '/admin/aditivo/listar',
      administradora: {},
      filtro: {},
      popupFiltro: false,
      dialog: false,
      dialogData: {
        titulo: '',
        items: []
      },
      filtroDataTable: {
        ativo: 0
      },
      headers: [
        { text: 'Nome do aditivo', value: 'nome_aditivo' },
        { text: 'Grupos', value: '', sortable: false },
        { text: 'Planos de venda', value: '', sortable: false },
        { text: 'Ativo', value: 'ativo' },
        { text: 'Ações', align: 'end', value: '', sortable: false }
      ],
      listaStatus: [
        { text: 'Ativo', color: '#85C151', checked: false, id: true },
        { text: 'Inativo', color: '#999999', checked: false, id: false }
      ]
    }),
    computed: {
      rules: () => Rules,
      filters: () => Filters,
      params () {
        return { pagination: this.pagination, id_administradora: this.administradora.id_administradora }
      }
    },
    mounted () {
      this.administradora = this.$session.get('administradora')
    },
    methods: {
      getAtivo (ativo) {
        return this.listaStatus.find((s) => s.id === ativo) || {}
      },
      abrirDialog (items, titulo) {
        this.dialogData.titulo = titulo
        this.dialogData.items = items.map(value => {
          return titulo === 'Grupos' ? value.cd_grupo : value.cd_plano
        })
        this.dialog = true
      },
      filtrar () {
        this.popupFiltro = false
        this.filtro = { ...this.filtroDataTable }
      },
      limpar () {
        this.filtroDataTable = {
          ativo: 0
        }
        this.filtrar()
      },
      alterarStatus (item) {
        alert({
          text: `Tem certeza que deseja ${item.ativo ? 'desativar' : 'ativar'} o aditivo "${item.nome_aditivo}"?`,
          showCloseButton: true,
          img_topo: false,
          buttons: {
            'Sim': () => { this.alteraStatus(item) },
            'Não': () => true
          }
        })
      },
      alteraStatus (item) {
        this.$http.put('/admin/aditivo/status', { id_aditivo: item.id_aditivo, ativo: !item.ativo })
          .then((r) => {
            if (r.data.ok) {
              this.filtro = { ...this.filtro }
            } else {
              error(null, r.data.msg_erro)
            }
          }).catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      visualizar (item) {
        window.open(`${this.ws}/aditivo/visualizar/${this.administradora.id_administradora}/${item.id_aditivo}`)
      },
      editar (item) {
        this.$router.push({ name: 'cadastrar_aditivo_contratual', params: { id_aditivo: `${item.id_aditivo}` } })
      },
      remover (item) {
        alert({
          text: `Tem certeza que deseja excluir permanentemente o aditivo ${item.nome_aditivo}?`,
          showCloseButton: true,
          img_topo: false,
          buttons: {
            'Excluir': () => { this.removeAditivo(item.id_aditivo) },
            'Cancelar': () => true
          }
        })
      },
      removeAditivo (id_aditivo) {
        this.$http.delete('admin/aditivo/remover', { params: { id_aditivo: id_aditivo } })
          .then(() => {
            alert('Aditivo removido com sucesso!')
            this.filtro = { ...this.filtro }
          })
          .catch(() => {
            error(null, 'Falha ao remover aditivo, tente novamente.')
          })
      }
    }
  }
</script>

<style scoped>

.menu-balloon {
  display: flex;
  flex-direction: column;
  background-color: #2B2B2B !important;
  justify-self: center;
  font-size: 12px;
  width: 170px !important;
  font-family: 'Product Sans',sans-serif;
  height: auto;
  border-radius: 8px !important;
  padding: 0 8px 0 8px;
}

.datatable {
  position: relative;
  z-index: 1;
}

.arrow-down-lil {
  margin-left: 143px !important;
}

.transparent {
  background-color: transparent!important;
  border-color: transparent!important;
}
.v-menu__content {
  background: transparent;
  box-shadow: none;
}

.short{
  width:70px;
}

.grupos-planos {
  border: lightgray 1px solid;
  border-radius: 10px;
  font-size: 12px;
  float: left;
  padding: 2px 6px 2px 6px;
  margin-right: 5px;
}

</style>
