<template>
  <v-container>
    <v-flex xs12 class="d-flex justify-center">
      <label>Assinatura</label>
    </v-flex>
    <v-flex xs12 class="d-flex mt-md-16 mr-10 ml-15 mb-10 justify-end" @click.stop="dialogAjuda()">
      <v-btn icon>
        <v-icon style="font-size: 32px;" color="primary">far fa-question-circle</v-icon>
      </v-btn>
    </v-flex>
    <v-flex xs12 class="d-flex mt-2 mr-10 ml-15 texto-large">
      <label style="line-height: 42px;">
        Leia atentamente o contrato ao lado antes de assinar
      </label>
    </v-flex>
    <v-flex xs12 class="mt-8 mr-10 ml-15">
      <p style="text-align: justify;font-size: 11px;">
        Essa alternativa eletrônica é válida legalmente [vide MP 2200-02/01, Art. 10, §2º] e apta a produzir efeitos a partir da conclusão do documento por todos os destinatários.
        Leia atentamente o contrato ao lado antes de assinar.
      </p>
    </v-flex>
    <v-flex xs12 class="mt-8 mr-10 ml-15">
      <v-btn x-large color="primary" class="button btn-default white--text" @click="assinatura">
        Assinar contrato
      </v-btn>
    </v-flex>
    <v-dialog v-model="dialog" width="400" :scrollable="true" content-class="app-content" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title class="text-h5">
          <v-flex class="d-flex">
            <v-flex style="font-weight: bold;">Ajuda</v-flex>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="dialog = false">
                  <v-icon class="black--text">fa fa-times</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-form ref="problemaArea" @submit.prevent="enviarProblema">
            <p class="black--text">Escreva detalhadamente os problemas ou dificuldades e entraremos em contato através do seu e-mail cadastrado na plataforma para resolver o ocorrido.</p>
            <label><strong class="ml-2 black--text">Descreva o problema</strong>
              <v-textarea v-model="problema" mt-1 height="96px" style="width: 350px;"
                          ref="problemaArea"
                          :counter="400"
                          maxlength="400"
                          :rules="[rules.required]"
                          no-resize outlined>
              </v-textarea>
            </label>
            <v-flex>
              <v-flex>
                <v-btn x-large color="primary" class="button btn-default white--text" type="submit">
                  Enviar mensagem
                </v-btn>
              </v-flex>
              <v-flex mt-1>
                <v-btn text x-large color="primary" class="btn-default" @click.stop="dialog = false">Cancelar</v-btn>
              </v-flex>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'
  import Rules from '@/plugins/validation'
  export default {
    name: 'Contrato',
    props: { administradora: Object, contrato: Object, assinar: Boolean },
    data: () => ({
      pdfsrc: '',
      dialog: false,
      problema: ''
    }),
    computed: {
      rules: () => Rules
    },
    mounted () {
      const id_administradora = this.administradora.id_administradora
      const id_documento = this.contrato.id_documento
      this.$http.get('/contrato/pdf', { params: { id_administradora: id_administradora, id_documento: id_documento }, responseType: 'blob' })
        .then((r) => {
          const blob = new Blob([r.data], { 'type': 'application/pdf' })
          this.pdfsrc = URL.createObjectURL(blob)
          this.$emit('getPdf', this.pdfsrc)
        })
        .catch(() => { error(null, 'Não foi possivel recuperar a proposta!') })
    },
    methods: {
      dialogAjuda () {
        this.dialog = true
        if (this.$refs.problemaArea) {
          this.$refs.problemaArea.reset()
        }
      },
      enviarProblema () {
        if (this.$refs.problemaArea.validate()) {
          this.dialog = false
          this.$http.post(`/contrato/error/${this.administradora.id_administradora}`, { problema: this.problema, id_signatario: this.contrato.id_signatario, id_documento: this.contrato.id_documento })
            .then(() => { alert('Sua requisição foi concluída com sucesso! Clique no botão abaixo para continuar sua operação.') })
        }
      },
      assinatura () {
        if (this.assinar) {
          this.$http.post('/contrato/registrar', { id_contrato: this.contrato.id_contrato, id_signatario: this.contrato.id_signatario })
            .then((r) => {
              if (r.data.ok) {
                alert({ text: r.data.msg, buttons: { 'Continuar': () => { this.$router.go(0) } } })
              } else { throw new Error() }
            })
            .catch(() => { error(null, 'Falha ao registrar assinatura! Tente novamente mais tarde.') })
        } else {
          error(null, 'Certifique-se de ler todo o documento antes de assiná-lo!')
        }
      }
    }
  }
</script>

<style scoped>

</style>
