import store from '../store'
import { isObject as _isObject } from 'vuetify/es5/util/helpers'

export const alert = (options, handler) => {
  if (!isObject(options) && handler) {
    options = {
      text: options, buttons: { 'Continuar': { focus: true, handler } }
    }
  }

  store.commit('alertShow', options)
}

export const error = (err, defaultMsg) => {
  if (!isObject(err)) {
    return new Promise((resolve) => {
      alert({ text: defaultMsg, buttons: { 'Continuar': resolve }, error: true })
    })
  }

  return new Promise(() => false)
}

export const unmask = v => {
  if (!v) {
    return ''
  }
  return v.replace(/[^\d]+/g, '')
}

export const isObject = _isObject
