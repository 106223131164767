<template>
  <v-container>
    <v-flex xs12 class="d-flex justify-center">
      <label>Validação</label>
    </v-flex>
    <!-- SMS e E-mail inicio -->
    <v-flex class="xs12 mt-md-10 mr-md-10 ml-md-15" v-if="administradora.tipo_validacao === 'email_e_sms'">
      <v-flex>
        <label>Enviamos dois códigos para validação via e-mail e SMS, utilizando o endereço {{ contrato.email }}</label>
        <v-flex class="d-flex mb-md-3">
          <v-btn text color="primary" @click="errorEmail" class="pa-0">ESSE NÃO É MEU E-MAIL</v-btn>
        </v-flex>
        <strong>Validação via E-mail *:</strong>
        <v-flex mt-2 class="d-flex justify-center">
          <MCodeInput style="width: 330px" :fields="5" :loading="false" @change="onChangeEmail" @complete="onCompleteEmail" />
        </v-flex>
        <v-flex mt-4>
          <label>e o número {{ contrato.celular | celularOfuscado }}</label>
        </v-flex>
        <v-flex class="d-flex">
          <v-btn text color="primary" @click="errorSms" class="pa-0">ESSE NÃO É MEU NÚMERO</v-btn>
        </v-flex>
        <strong>Validação via SMS *:</strong>
        <v-flex mt-2 class="d-flex justify-center">
          <MCodeInput style="width: 330px" :fields="5" :loading="false" @change="onChangeSms" @complete="onCompleteSms" />
        </v-flex>
      </v-flex>
    </v-flex>
    <!-- SMS e E-mail fim -->
    <!-- Somente E-mail início -->
    <v-flex xs12 class="mt-5 mt-md-16 mr-md-10 ml-md-15" v-if="administradora.tipo_validacao === 'somente_email'">
      <label>Enviamos um código para validação via e-mail para {{ contrato.email }}</label>
      <v-flex class="d-flex mb-md-3">
        <v-btn text color="primary" @click="errorEmail" class="pa-0">ESSE NÃO É MEU E-MAIL</v-btn>
      </v-flex>
      <strong>Validação via E-mail *:</strong>
      <v-flex mt-2 class="d-flex justify-center">
        <MCodeInput style="width: 330px" :fields="5" :loading="false" @change="onChangeEmail" @complete="onCompleteEmail" />
      </v-flex>
    </v-flex>
    <!-- Somente E-mail fim -->
    <!-- Somente SMS início-->
    <v-flex xs12 class="mt110 mr-10 ml-15" v-if="administradora.tipo_validacao === 'somente_sms'">
      <label>Enviamos um código para validação via SMS para {{ contrato.celular | celularOfuscado }}</label>
      <v-flex class="d-flex">
        <v-btn text color="primary" @click="errorSms" class="pa-0">ESSE NÃO É MEU NÚMERO</v-btn>
      </v-flex>
      <strong>Validação via SMS *:</strong>
      <v-flex mt-2 class="d-flex justify-center">
        <MCodeInput style="width: 330px" :fields="5" :loading="false" @change="onChangeSms" @complete="onCompleteSms" />
      </v-flex>
    </v-flex>
    <!-- Somente SMS fim -->
    <!-- Email ou SMS inicio -->
    <v-flex xs12 class="mt110 mr-10 ml-15" v-if="administradora.tipo_validacao === 'email_ou_sms'">
      <label>Enviamos um código para validação via SMS para o número {{ contrato.celular | celularOfuscado }} e o mesmo para o E-mail {{ contrato.email }}</label>
      <v-flex class="d-flex">
        <v-btn text color="primary" @click="erroUnico" class="pa-0">ESSES NÃO SÃO MEUS DADOS</v-btn>
      </v-flex>
      <strong>Validação de token *:</strong>
      <v-flex mt-2 class="d-flex justify-center">
        <MCodeInput style="width: 330px" :fields="5" :loading="false" @change="onChangeUnico" @complete="onCompleteUnico" />
      </v-flex>
    </v-flex>
    <!-- mail ou SMS fim -->
    <v-flex xs12 class="mt-8 mr-10 ml-15">
      <v-btn x-large color="primary" class="button btn-default white--text" @click="nextPage">
        VALIDAR TOKEN{{ administradora.tipo_validacao.includes('somente') ? '':'S' }}
      </v-btn>
    </v-flex>
    <v-flex xs12 class="mt-2 mr-10 ml-15">
      <v-btn text x-large color="primary" class="btn-default" @click="enviarNovamente">Enviar novamente</v-btn>
    </v-flex>
  </v-container>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'
  import MCodeInput from '@/views/contrato/pages/components/MCodeInput'

  export default {
    name: 'Validacao',
    components: {
      MCodeInput
    },
    filters: {
      capitalize (value) {
        if (!value) return ''
        value = value.toString()
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    props: { contrato: Object, administradora: Object },
    data: () => ({
      token_email: '',
      token_sms: '',
      token_unico: '',
      scrollPosition: null
    }),
    mounted () {
      this.gerarToken(false)
    },
    methods: {
      enviarNovamente () {
        this.gerarToken(true)
      },
      erroUnico () {
        this.$http.post(`/unico/error/${this.administradora.id_administradora}`, { id_documento: this.contrato.id_documento, id_signatario: this.contrato.id_signatario })
          .then(() => {
            let nome = this.administradora.nome.toString()
            nome = nome.charAt(0).toUpperCase() + nome.slice(1)
            alert(`Foi enviado um e-mail para a ${nome} verificar a inconsistência cadastral do seu contrato.`)
          }).catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      errorEmail () {
        this.$http.post(`/email/error/${this.administradora.id_administradora}`, { id_documento: this.contrato.id_documento, id_signatario: this.contrato.id_signatario })
          .then(() => {
            let nome = this.administradora.nome.toString()
            nome = nome.charAt(0).toUpperCase() + nome.slice(1)
            alert(`Foi enviado um e-mail para a ${nome} verificar a inconsistência cadastral do seu contrato.`)
          }).catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      errorSms () {
        this.$http.post(`/sms/error/${this.administradora.id_administradora}`, { id_documento: this.contrato.id_documento, id_signatario: this.contrato.id_signatario })
          .then(() => {
            let nome = this.administradora.nome.toString()
            nome = nome.charAt(0).toUpperCase() + nome.slice(1)
            alert(`Foi enviado um e-mail para a ${nome} verificar a inconsistência cadastral do seu contrato.`)
          }).catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      gerarToken (show_alert) {
        const id_administradora = this.administradora.id_administradora
        const id_signatario = this.contrato.id_signatario
        this.$http.get(`/contrato/token`, { params: { id_administradora: id_administradora, id_signatario: id_signatario, id_contrato: this.contrato.id_contrato } })
          .then(() => { if (show_alert) alert('Token reenviado com sucesso!') })
          .catch(() => { error(null, 'Falha ao reenviar o token, tente novamente mais tarde.') })
      },
      onCompleteEmail (token_digitado) {
        this.token_email = token_digitado
      },
      onChangeEmail () {
        this.token_email = ''
      },
      onCompleteSms (token_digitado) {
        this.token_sms = token_digitado
      },
      onChangeSms () {
        this.token_sms = ''
      },
      onChangeUnico () {
        this.token_unico = ''
      },
      onCompleteUnico (token_digitado) {
        this.token_unico = token_digitado
      },
      nextPage () {
        const data = {
          id_administradora: this.administradora.id_administradora,
          id_signatario: this.contrato.id_signatario,
          id_contrato: this.contrato.id_contrato,
          email_token: this.token_email,
          sms_token: this.token_sms,
          unico_token: this.token_unico }
        let ok = true
        if (this.administradora.tipo_validacao === 'email_ou_sms' && this.token_unico.length < 5) {
          ok = false
        }
        if (ok) {
          this.$http.post('/contrato/valida/token', data)
            .then((r) => {
              if (r.data.ok) { this.$emit('nextPage') } else { error(null, r.data.msg_erro) }
            }).catch(() => error(null, 'Token inválido!'))
        } else {
          error(null, 'Preencha o token corretamente!')
        }
      }
    }
  }
</script>

<style scoped>
 strong {
   font-family: "Product Sans", sans-serif;
 }
</style>
