// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their file name.

import Vue from 'vue'

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
  // Look for files in the directory
  '../components',
  // Do not look in subdirectories
  false,
  // Only include .vue files
  /\.vue$/
)

const registered = Object.keys(Vue.options.components)

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
  // Get the component config
  const componentConfig = requireComponent(fileName)
  // Get the PascalCase version of the component name
  const componentName = fileName
  // Remove the "./" from the beginning
    .replace(/^\.\//, '')
  // Remove the file extension from the end
    .replace(/\.\w+$/, '')

  if (!registered.includes(componentName)) {
    console.warn(`Component ${componentName} not registered in mixins.js`)
    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
  }
})
