<template>
  <v-layout wrap class="app-content">
    <m-form class="col-3 pa-0" ref="form" @submit.prevent="logar">
      <v-flex style="min-height: 81%;" class="pt-16">
        <v-flex class="ml-15 texto-large pt-16 mr-10">
          <label>
            Acesse a gestão de assinaturas do seu consórcio
          </label>
        </v-flex>
        <v-flex xs12 mt-9 mr-10 ml-15>
          <label class="label-input" for="login">Login</label>
          <m-text-field
              id="login"
              placeholder="Login"
              v-model="model.login"
              v-lowercase
              dense
              :rules="[rules.required, rules.email]"
              outlined
              validate-on-blur />
        </v-flex>
        <v-flex xs12 mt-n2 mr-10 ml-15>
          <label class="label-input" for="Senha">Senha</label>
          <m-text-field
              id="Senha"
              placeholder="Senha"
              v-model="model.senha"
              :rules="[rules.required]"
              type="password"
              dense
              outlined
              validate-on-blur />
        </v-flex>
        <v-flex mr-10 ml-15 class="d-flex">
          <v-btn color="primary" x-large class="button btn-default" type="submit">Entrar</v-btn>
        </v-flex>
      </v-flex>
      <v-flex class="footer col-12">
        <v-flex xs12 mt-3 class="d-flex column justify-center">
          <img src="../assets/img/logo_assinatura.png" alt="Logo Assinatura">
        </v-flex>
        <v-flex xs12 ma-3 mb-2 class="d-flex justify-center font-link">
        </v-flex>
      </v-flex>
    </m-form>

    <v-flex class="col-9 bg"></v-flex>
  </v-layout>
</template>

<script>
  import Rules from '../plugins/validation'
  import MForm from '../components/MForm'
  import { error } from '@/plugins/helpers'
  export default {
    name: 'Login',
    components: { MForm },
    data: () => ({
      model: {
        login: '',
        senha: ''
      }
    }),
    computed: {
      rules: () => Rules
    },
    methods: {
      async logar () {
        if (this.$refs.form.validate()) {
          try {
            const { data } = await this.$http.post('/login', this.model)
            this.$session.set('token', data.token)
            this.$session.set('administradora', Object.assign(data.administradora))

            // noinspection JSIgnoredPromiseFromCall
            this.$router.replace('/')
          } catch (e) {
            error(null, 'Não foi possível realizar o login!')
          }
        } else {
          this.$refs.form.validate()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .background {
    background: var(--v-primary-base);
  }
  .form-layout {
    margin: auto;
    max-width: 500px;
  }
  .footer {
    background-color: #F7F7F7;
    font-size: 12px;
  }
  .bg {
    background: linear-gradient(to right,#0E6C3C66, #0E6C3C66), url(../assets/img/login-bg-min.jpg) center center/cover no-repeat;
    height: 100%;
    position: fixed;
    right: 0;
    min-height: 100%;
  }
</style>
