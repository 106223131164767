<template>
  <v-dialog v-model="dialog" width="500" content-class="app-content">
    <v-card>
      <v-card-title>
        <span class="text-h5">Seleção de contratos via CSV</span>
      </v-card-title>
      <v-card-text>
        <p class="text-center">Faça o upload de um arquivo em formato .CSV para importar a seleção de contratos</p>
        <v-file-input
            v-model="file"
            label="Upload de arquivo:"
            prepend-icon="mdi-paperclip"
            :show-size="1000"
            accept=".csv"
            @change="validateFile"
        />
        <v-btn class="ma-2 home-btn" outlined color="primary" @click="downloadTemplate">Modelo do arquivo</v-btn>
        <v-btn color="primary" @click="importContracts">Importar contratos</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="ma-2 home-btn" outlined color="primary" text @click="close">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'

  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      status: Number
    },
    data: () => ({
      file: null
    }),
    watch: {
      dialog (newValue) {
        if (!newValue) {
          this.file = null
        }
      }
    },
    methods: {
      validateFile () {
        if (this.file.size > 10000000) {
          error(null, 'O arquivo enviado possui mais de 10MB, efetue um novo envio.')
          this.file = null
        }
      },
      downloadTemplate () {
        this.$http.get('/get/contratos.csv', {
          responseType: 'blob'
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'contratos.csv')
          document.body.appendChild(link)
          link.click()
        })
      },
      importContracts () {
        if (!this.file) {
          error(null, 'Nenhum arquivo selecionado.')
          return
        }

        if (this.file.name.split('.').pop() !== 'csv') {
          error(null, 'O arquivo enviado não é do tipo \'.csv\', efetue um novo envio.')
          return
        }

        const formData = new FormData()
        formData.append('file', this.file)
        formData.append('status', this.$props.status)
        this.$http.post('/importCsv', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((result) => {
          if (result.data.not_found.length > 0) {
            alert(`${result.data.not_found.length} contrato(s) não foram encontrado(s) no sistema!`)
          } else {
            alert('Importação(ões) realizada(s) com sucesso')
          }

          this.$emit('close-import', result.data.found)
          this.dialog = false
        }).catch(() => {
          error(null, 'Importação falhou')
        })
      },
      close () {
        this.dialog = false
        this.$emit('close-dialog-import')
      }
    }
  }
</script>
