<template>
  <v-container>
    <v-flex xs12 class="d-flex justify-center">
      <label>Selecionar Contrato</label>
    </v-flex>
    <div v-for="(contrato) in contratos" :key="contrato" class="sm12 mr-md-10 ml-md-15 mt-md-16">
      <v-flex class="xs12 mt-8 mr-10 ml-md-15 d-flex">
        <v-icon color="primary">icon-car</v-icon>
        <label class="align-center ml-4 nome-bem fonte-media">{{ contrato.nm_bem }}</label>
      </v-flex>
      <v-divider class="mr-md-10 ml-md-15 mt-md-8 mb-md-10"></v-divider>
      <v-flex xs12 class="mt-4">
        <v-row class="d-flex mr-md-10 ml-md-15">
          <v-col class="pa-0 fonte-padrao">
            Número da proposta
          </v-col>
          <v-col class="d-flex justify-end pa-0 pb-2 fonte-media">
            {{ contrato.id_documento }}
          </v-col>
        </v-row>
        <v-row class="d-flex mr-md-10 ml-md-15">
          <v-col class="pa-0 fonte-padrao">
            Primeira Parcela
          </v-col>
          <v-col class="d-flex justify-end pa-0 pb-2 fonte-media">
            {{ formatarReal(contrato.vl_parcela_incial) }}
          </v-col>
        </v-row>
        <v-row class="d-flex mr-md-10 ml-md-15">
          <v-col class="pa-0 fonte-padrao">
            Número de Parcelas
          </v-col>
          <v-col class="d-flex justify-end pa-0 pb-2 fonte-media">
            {{ contrato.no_parcelas }}
          </v-col>
        </v-row>
        <v-row class="d-flex mr-md-10 ml-md-15">
          <v-col class="align-self-center pa-0" style="font-size: 18px">
            Valor do Crédcontratoo
          </v-col>
          <v-col class="d-flex justify-end text-md-h5 text-xs-h5 pa-0 font-weight-bold valor-bem">
            {{ formatarReal(contrato.vl_bem) }}
          </v-col>
        </v-row>
        <v-row class="mt-8 mb-16 mr-10 ml-15">
          <v-btn x-large color="primary " outlined class="btn-default" @click="selecionarCotrato(contrato.id_contrato)">
            Continuar
            <v-icon right color="primary" style="font-size: 14px !important" class="arrow-right">fas fa-arrow-right</v-icon>
          </v-btn>
        </v-row>
      </v-flex>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'SelecionarProposta',
    props: { administradora: Object, contratos: Array },
    data: () => ({
      id_documento: '',
      nm_bem: '',
      vl_parcela_incial: '',
      no_parcelas: '',
      vl_bem: ''
    }),
    methods: {
      formatarReal (value) {
        return 'R$ ' + Number(value).toLocaleString('pt-BR', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      },
      selecionarCotrato (id_contrato) {
        this.$emit('selecionarCotrato', id_contrato)
      }
    }
  }
</script>

<style scoped>
label.nome-bem {
  font-family: Product Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
}
.arrow-right {
  font-size: 18px !important;
}
.valor-bem {
  font-size: 1.2rem !important;
}
</style>
