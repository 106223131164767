import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'
import VueAxios from 'vue-axios'
import md5 from 'md5'
import moment from 'moment'
import router from '../router'
import { get, includes } from 'lodash'
import axiosCancel from 'axios-cancel'
import store from '../store'
import session from './session'

const http = axios.create({
  baseURL: process.env.VUE_APP_WS_ROOT,
  timeout: 600000
})

export default http

axiosCancel(http)

Vue.use(VueAxios, http)

let requestId = 0

http.interceptors.request.use(function (config) {
  config['requestId'] = get(config, 'requestId', ++requestId)

  // envia a chave dinamica do ws
  config.headers['X-Key'] = md5('newm' + moment().format('YY-MM-DD-HH'))

  if (session.exists()) {
    // envia o token JWT
    config.headers['Authorization'] = `Bearer ${session.get('token')}`
  }

  const options = config['options'] = {
    silent: false,
    unguarded: false,
    ...config['options']
  }

  if (!options.unguarded && router.currentRoute.meta.public) {
    options.unguarded = true
  }

  if (!options.silent) {
    store.commit('loadingStart')
  }

  if (!config.url) {
    config.url = '/'
  }

  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true
    })
  }

  if (!options.unguarded && session.exists() && session.get('autenticar')) {
    if (process.env.NODE_ENV === 'development') {
      console.info(`» ${config.url}\tAguardando autenticação...`)
    }
    throw new AutenticarError(config, session.get('senha_invalida'))
  }

  return config
}, error => {
  const silent = get(error, 'config.options.silent', false)
  if (!silent) {
    store.commit('loadingDone')
  }
  return Promise.reject(error)
})

http.interceptors.response.use(function (response) {
  const silent = get(response, 'config.options.silent', false)
  if (!silent) {
    store.commit('loadingDone')
  }

  const token = response.headers['authorization']
  if (token) {
    session.set('token', token.replace(/^(Bearer )/, ''))
  }

  return response
}, error => {
  const silent = get(error, 'config.options.silent', false)
  if (!silent) {
    store.commit('loadingDone')
  }

  const status = get(error, 'response.status', 500)
  const msg_erro = get(error, 'response.msg_erro')

  if (!msg_erro && session.exists() && includes([401, 403], status)) {
    router.replace({ name: 'logout' })
    return new Promise(() => false)
  } else {
    return Promise.reject(error)
  }
})

router.beforeEach((to, from, next) => {
  http.cancelAll()
  next()
})

class AutenticarError extends Error {
  constructor (config, senha_invalida) {
    super()
    this.config = config
    this.senha_invalida = !!senha_invalida
  }
}
