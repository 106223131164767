import Vue from 'vue'
import Router from 'vue-router'
import Sistema from './views/Sistema'
import Home from './views/home/Home'
import NotFound from './views/NotFound'
import Login from './views/Login'
import Acesso from '@/views/contrato/Acesso'
import Usuario from '@/views/Usuario'
import Parametrizacao from '@/views/parametrizacao/Parametrizacao'
import RelatorioAssinaturas from './views/RelatorioAssinaturas'
import CadastrarContrato from '@/views/home/CadastrarContrato'
import DesativarSistema from './views/parametrizacao/DesativarSistema'
import ValidadorContratoAssinado from './views/ValidadorContratoAssinado'
import AditivosContratuais from './views/aditivos/AditivosContratuais'
import CadastrarAditivoContratual from './views/aditivos/CadastrarAditivoContratual'
import Docs from './views/Docs'
import DocsDev from './views/DocsDev'
// plugins
import session from './plugins/session'

Vue.use(Router)

// noinspection JSUnusedGlobalSymbols
const router = new Router({
  routes: [
    {
      path: '/docs',
      name: 'docs',
      component: Docs,
      meta: { title: 'Documentação', public: true }
    },
    {
      path: '/docsDev',
      name: 'docsDev',
      component: DocsDev,
      meta: { title: 'Documentação DEV', public: true }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'Login', public: true }
    },
    {
      path: '/contrato/acesso/:hash',
      name: 'contrato-acesso',
      component: Acesso,
      meta: { title: 'Assinatura digital de contrato', public: true },
      props: true
    },
    {
      path: '/valida-contrato',
      name: 'valida-contrato',
      component: ValidadorContratoAssinado,
      meta: { title: 'Validador de contratos assinados', public: true }
    },
    {
      path: '/',
      component: Sistema,
      children: [
        {
          path: '/',
          redirect: '/home'
        },
        {
          path: '/home',
          name: 'home',
          component: Home,
          meta: { title: 'Home' }
        },
        {
          path: '/painel/cadastro',
          name: 'cadastro_contrato',
          component: CadastrarContrato,
          meta: { title: 'Cadastro Contrato' }
        },
        {
          path: '/usuario',
          name: 'usuario',
          component: Usuario,
          meta: { title: 'Usuário' }
        },
        {
          path: '/parametrizacao',
          name: 'parametrizacao',
          component: Parametrizacao,
          meta: { title: 'Parametrização' }
        },
        {
          path: '/desativarsistema',
          name: 'desativarsistema',
          component: DesativarSistema,
          meta: { title: 'Desativar sistema' }
        },
        {
          path: '/relatorioassinaturas',
          name: 'relatorioassinaturas',
          component: RelatorioAssinaturas,
          meta: { title: 'Relatório de Assinaturas' }
        },
        {
          path: '/aditivo-contratual',
          name: 'aditivo_contratual',
          component: AditivosContratuais,
          meta: { title: 'Aditivos contratuais' }
        },
        {
          path: '/aditivos-contratuais/cadastro',
          name: 'cadastrar_aditivo_contratual',
          component: CadastrarAditivoContratual,
          meta: { title: 'Aditivo contratual' }
        },
        {
          path: '/*',
          name: 'nao-encontrada',
          component: NotFound,
          meta: { title: 'Página não encontrada' }
        }
      ]
    },
    {
      path: '/logout',
      name: 'logout',
      meta: { title: 'Sair' },
      beforeEnter: (to, from, next) => {
        session.destroy()
        next({ name: 'login' })
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || ''
  checkSession(to, next)
})

function checkSession (to, next) {
  if (session.exists()) {
    if (to.name === 'login') {
      next({ path: '/' })
    } else {
      next()
    }
  } else if (!to.meta.public) {
    next({ name: 'login' })
  } else {
    next()
  }
}

export default router
