<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Gerenciamento de usuários</h1>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex offset-xs4 offset-md-6 xs4 md2 align-end>
        <v-flex mt-5>
          <v-btn color="primary" class="button btn-default white--text" @click="novoUsuario">Adicionar usuário</v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout column mt-3 class="card">
        <v-flex row md2 mt-2>
          <v-layout column class="datatable">
            <v-flex>
              <v-menu content-class="menu-card mt-1 app-content"
                      min-width="110px"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y
                      v-model="popupFiltro">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text class="v-btn filtrar" color="primary" @click="popupFiltro = true">
                    <v-icon left>icon-filter</v-icon>
                    Filtrar
                  </v-btn>
                </template>
                <div class="filtro-div">
                  <label class="label-input">Filtro por nome ou e-mail</label>
                  <m-text-field ref="textField" v-model="filtroTexto"
                                color="primary"
                                placeholder="Filtrar por nome ou e-mail ..."
                                dense
                                outlined
                                @keydown.stop.enter="filtrar" />
                  <v-flex class="d-flex justify-end">
                    <v-btn color="primary" outlined class="v-btn btn-filtar mr-2" @click="limpar">Limpar</v-btn>
                    <v-btn color="primary" class="v-btn btn-filtar" @click="filtrar">Filtrar</v-btn>
                  </v-flex>
                </div>
              </v-menu>
            </v-flex>
            <DataTable :url="url" :headers="headers" :params="params" :filter="filtro">
              <template v-slot:items="item">
                <td>{{ item.nome }}</td>
                <td>{{ item.login }}</td>
                <td>
                  <v-layout>
                    <v-menu origin="center center"
                            top
                            :offset-x="false"
                            :offset-y="true"
                            class="transparent"
                            transition="slide-y-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn class="btn-list" elevation="0" color="transparent" v-bind="attrs" v-on="on">
                          ...
                        </v-btn>
                      </template>
                      <v-list class="tooltip menu-balloon">
                        <div>
                          <v-list-item @click="editarUsuario(item)">
                            <v-flex class="d-flex justify-center">Editar</v-flex>
                          </v-list-item>
                        </div>
                        <v-divider style="border-color:white !important"></v-divider>
                        <div>
                          <v-list-item @click="apagarUsuario(item)">
                            <v-flex class="d-flex justify-center">Apagar</v-flex>
                          </v-list-item>
                        </div>
                      </v-list>
                      <div class="arrow-down-lil"></div>
                    </v-menu>
                  </v-layout>
                </td>
              </template>
            </DataTable>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-dialog v-model="dialog" width="400" :scrollable="true" content-class="app-content" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title class="text-h5">
          <v-flex class="d-flex">
            <v-flex>{{ editar ? 'Editar usuário' : 'Novo usuário' }}</v-flex>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="dialog = false">
                  <v-icon>fa fa-times</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" @submit.prevent="salvarUsuario">
            <v-flex mt-2>
              <v-flex>
                <label class="label-input">Nome *</label>
                <m-text-field v-model="model.nome"
                              placeholder="Nome"
                              :rules="[rules.required]"
                              v-input-filter="[filters.text]"
                              v-uppercase
                              dense
                              outlined
                              validate-on-blur />
              </v-flex>
              <v-flex mt-n2>
                <label class="label-input">E-mail *</label>
                <m-text-field v-model="model.login"
                              placeholder="E-mail"
                              :rules="[rules.required, rules.email]"
                              v-lowercase
                              dense
                              outlined
                              validate-on-blur />
              </v-flex>
              <v-flex mt-n2>
                <label class="label-input">Senha {{ editar ? '' : '*' }}</label>
                <m-text-field v-model="model.senha"
                              placeholder="Senha"
                              :rules="editar ? [] : [rules.required]"
                              :type="pass_visible ? 'text' : 'password'"
                              dense
                              outlined
                              validate-on-blur>
                  <v-icon slot="append" @click="pass_visible = !pass_visible"
                          :color="pass_visible ? 'primary' : undefined">
                    {{ pass_visible ? 'far fa-eye' : 'far fa-eye-slash' }}
                  </v-icon>
                </m-text-field>
              </v-flex>
              <v-flex mt-n2>
                <label class="label-input">Confirmar senha {{ editar ? '' : '*' }}</label>
                <m-text-field v-model="model.confirmar_senha"
                              placeholder="Confirmar senha"
                              :type="pass_visible ? 'text' : 'password'"
                              :rules="editar ? [] : [rules.required]"
                              :error-messages="validar_senha(model.senha, model.confirmar_senha)"
                              dense
                              outlined
                              validate-on-blur>
                  <v-icon slot="append" @click="pass_visible = !pass_visible"
                          :color="pass_visible ? 'primary' : undefined">
                    {{ pass_visible ? 'far fa-eye' : 'far fa-eye-slash' }}
                  </v-icon>
                </m-text-field>
              </v-flex>
            </v-flex>
            <v-flex>
              <v-flex>
                <v-btn x-large color="primary" class="button btn-default white--text" type="submit">
                  Salvar Usuário
                </v-btn>
              </v-flex>
              <v-flex mt-1>
                <v-btn text x-large color="primary" class="btn-default" @click.stop="dialog = false">Cancelar</v-btn>
              </v-flex>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Rules from '@/plugins/validation'
  import { alert, error } from '@/plugins/helpers'
  import DataTable from '../components/DataTable'
  import Filters from '@/plugins/filters'

  export default {
    name: 'Usuario',
    components: { DataTable },
    data: () => ({
      url: '/admin/usuarios',
      filtroTexto: '',
      items: [],
      dialog: false,
      pass_visible: false,
      popupFiltro: false,
      administradora: {},
      model: {
        id_usuario: 0,
        nome: '',
        login: '',
        senha: '',
        confirmar_senha: ''
      },
      editar: false,
      filtro: {
        nome: '',
        login: ''
      },
      pagination: {
        sortBy: ['nome']
      }
    }),
    computed: {
      rules: () => Rules,
      filters: () => Filters,
      params () {
        return { pagination: this.pagination, id_administradora: this.administradora.id_administradora }
      },
      headers () {
        return [
          { text: 'Nome', value: 'nome' },
          { text: 'E-mail', value: 'login' },
          { text: 'Ações', value: 'acao', sortable: false }
        ]
      }
    },
    mounted () {
      this.administradora = this.$session.get('administradora')
    },
    methods: {
      filtrar () {
        this.popupFiltro = false
        this.filtro = { 'nome|login': this.filtroTexto }
        this.filtro = { ...this.filtro }
      },
      limpar () {
        this.filtroTexto = ''
        this.filtrar()
      },
      validar_senha (senha, confirmar_senha) {
        return (this.isEmpty(confirmar_senha) || senha === confirmar_senha) ? '' : 'As senhas não correspondem!'
      },
      isEmpty (texto) {
        return !texto || texto.length === 0
      },
      limparValidation () {
        this.$nextTick(() => {
          if (this.$refs.form) {
            this.$refs.form.resetValidation()
          }
        })
      },
      novoUsuario () {
        this.editar = false
        this.model = {
          id_usuario: 0,
          nome: '',
          login: '',
          senha: '',
          confirmar_senha: ''
        }
        this.dialog = true
        this.limparValidation()
      },
      editarUsuario (usuario) {
        this.model = usuario
        this.dialog = true
        this.editar = true
        this.limparValidation()
      },
      apagarUsuario (usuario) {
        alert({
          text: `Confirma exclusão do usuário ${usuario.nome}?`,
          showCloseButton: true,
          img_topo: false,
          buttons: {
            'Excluir': () => { this.removeUsuario(usuario.id_usuario) },
            'Cancelar': () => true
          }
        })
      },
      removeUsuario (id_usuario) {
        this.$http.delete(`/admin/usuario`, { params: { id_usuario: id_usuario } })
          .then(() => {
            alert('Usuário removido com sucesso!')
            this.filtro = { ...this.filtro }
          }).catch(() => {
            error(null, 'Falha ao remover usuário, tente novamente.”')
          })
      },
      salvarUsuario () {
        if (this.$refs.form.validate()) {
          if (this.model.senha === this.model.confirmar_senha) {
            const id_administradora = this.administradora.id_administradora
            this.$http.post(`/admin/cadastrar/${id_administradora}`, this.model)
              .then(() => {
                alert(`Usuário ${this.editar ? 'editado' : 'cadastrado'} com sucesso!`)
                this.filtro = { ...this.filtro }
                this.dialog = false
              }).catch(() => {
                error(null, `Falha ao ${this.editar ? 'editado' : 'cadastrado'} usuário, tente novamente.`)
              })
          }
        }
      }
    }
  }
</script>

<style scoped>

.menu-balloon {
  display: flex;
  flex-direction: column;
  background-color: #2B2B2B !important;
  justify-self: center;
  font-size: 12px;
  width: 120px !important;
  font-family: 'Product Sans',sans-serif;
  height: auto;
  border-radius: 8px !important;
  padding: 0 8px 0 8px;
}

.arrow-down-lil {
  margin-left: 8px !important;
}

.filtrar{
  text-transform:none;
  border-radius: 18px;
}

.btn-filtar {
  font-size: 12px !important;
  border-radius: 12px !important;
}

.datatable {
  position: relative;
  z-index: 1;
}

.carregando {
  position: absolute;
  bottom: 15px;
  z-index: 3;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  user-select: none;
  background: #ffffff55;
}
.transparent {
  background-color: transparent!important;
  border-color: transparent!important;
}
.v-menu__content {
  background: transparent;
  box-shadow: none;
}
</style>
