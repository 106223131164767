<template>
  <m-text-field ref="field"
                v-bind="$attrs"
                v-mask="mask"
                :rules="m_rules"
                v-model="date"
                :disabled="disabled"
                :class="{ required }"
                outlined>
    <v-icon slot="append" color='primary'>
      far fa-calendar
    </v-icon>
  </m-text-field>
</template>
<script>

  export default {
    name: 'DateField',
    props: { value: String, rules: Array, disabled: Boolean },
    computed: {
      date: {
        get () {
          return this.disabled ? this.value : this.toDateBR(this.value)
        },
        set (value) {
          if (!this.disabled) {
            this.$emit('input', this.toDateUS(value))
          }
        }
      },
      m_rules () {
        return this.disabled ? [] : [this.$rules.date, ...this.rules]
      },
      required () {
        return this.rules && this.rules.indexOf(this.$rules.required) >= 0
      },
      mask () {
        return {
          mask: this.disabled ? '********' : '##/##/####',
          tokens: {
            '#': { pattern: /\d/ },
            '*': { pattern: /./ }
          }
        }
      }
    },
    methods: {
      toDateBR (value) {
        return this.toggleDateFormat(value, '-', '/')
      },
      toDateUS (value) {
        return this.toggleDateFormat(value, '/', '-')
      },
      toggleDateFormat (value, glue, changeTo) {
        return value && value.split(glue).reverse().join(changeTo)
      },
      reset () {
        this.$refs.field.reset()
      }
    }
  }
</script>
