const filters = {
  numeric: '0123456789',
  alpha: 'ABCDEFGHIJKLMNOPQRSTUVXZWYabcdefghijklmnopqrstuvxzwy ',
  utf8: 'àáâãäçèéêëìíîïñòóôõöùúûüýÿÀÁÂÃÄÇÈÉÊËÌÍÎÏÑÒÓÔÕÖÙÚÛÜÝ '
}

filters.text = filters.alpha + filters.utf8
filters.alphanumeric = filters.text + filters.numeric

filters.sms = filters.alpha + filters.numeric + '_!@"#$%&()*+,./-:;<=>?{\\}'

export default filters
