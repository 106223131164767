<template>
  <li :class="initialClass" @click="activate($event)">
    <div class="home-pseudo-t">
      <v-icon class="home-tab-icon">{{ icon }}</v-icon>
      <p class="home-t-content-text">{{ text }}</p>
      <div v-if="counter > 0" class="counter">{{ counter }}</div>
    </div>
  </li>
</template>
<script>
  export default {
    name: 'HomeTab',
    props: {
      text: String,
      icon: String,
      counter: Number,
      active: Boolean
    },
    data: () => ({
      initialClass: ''
    }),
    mounted () {
      if (this.active) this.initialClass = 'home-tab-active'
    },
    methods: {
      activate (event) {
        this.$emit('change')
        document.querySelector('.home-tab-active').classList.remove('home-tab-active')

        if (event.target.classList[0] !== 'home-pseudo-t') {
          event.target.parentNode.parentNode.classList.add('home-tab-active')
        } else {
          event.target.parentNode.classList.add('home-tab-active')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .home-tabs li {
    float: left;
  }
  .home-tabs div {
    float: left;
    padding: 10px;
    max-height: 50px;

    color: #777777;
    background: #f5f5f5;

    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    -moz-border-radius-topleft: 10px;
    -moz-border-radius-topright: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .home-pseudo-t {
    min-width: 100px;
    display: inline-block;
    cursor: pointer;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    transform-origin: left center 0;
    transform: perspective(4px) rotateX(0.5deg);

    z-index: 1;
  }
  .home-t-content-text {
    z-index: 1;
    margin-left: 5px;
    display: inline-block;
    transform-origin: left center 0;
    transform: perspective(4px) rotateX(-0.6deg);
    font-size: 12px;
  }
  .home-tab-icon {
    margin-top: 2px;
    float: left;
    font-size: 20px;
  }
  .home-pseudo-t div.counter {
    display: inline-block;
    border-radius: 50px;
    font-size: 10px;
    background: #777777;
    float: right;
    font-weight: bold;
    padding: 0 6px 0 6px;
    margin-left: 10px;
    margin-right: 7px;
    margin-top: 4px;
    color: white;
    min-width: 30px;
    text-align: center;

    transform-origin: left center 0;
    transform: perspective(4px) rotateX(-1.2deg);
  }
  .home-tab-active div.counter {
    background: var(--v-primary-base);
    color: white;
  }
  .home-tab-active i {
    z-index: 3;
    background: white;
    color: var(--v-primary-base); /* Muda a cor do icone da aba ativa */
  }
  .home-tab-active {
    z-index: 3;
  }
  .home-tabs .home-tab-active div.home-pseudo-t {
    height: 39px;
    background: white;
    color: black;
  }
</style>
