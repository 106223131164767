<template>
  <div>
    <h1>API Documentation</h1>
    <swagger-ui :spec="swaggerSpec" :deep-linking="true"></swagger-ui>
  </div>
</template>

<script>
  import SwaggerUI from 'swagger-ui'
  import 'swagger-ui/dist/swagger-ui.css'

  export default {
    name: 'SwaggerDoc',
    data () {
      return {
        swaggerSpec: {
          swagger: '2.0',
          info: {
            title: 'API Assinatura Consórcio',
            version: '1.0.2'
          },
          paths: {
            '/login': {
              post: {
                tags: ['Login'],
                summary: 'Login',
                description: 'Login',
                operationId: 'login',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Login',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contratos/status': {
              get: {
                tags: ['Contrato'],
                summary: 'Retora o status dos contratos',
                description: 'Retora o status dos contratos',
                operationId: 'statusContratos',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'id',
                    in: 'body',
                    description: 'Array de ids de contratos',
                    required: true,
                    type: 'array'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/usuario/{id_usuario}/contratos/{id_documento}/reenvio': {
              get: {
                tags: ['Contrato'],
                summary: 'Reenvia o email de assinatura do contrato',
                description: 'Reenvia o email de assinatura do contrato',
                operationId: 'reenviarEmailContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'id_usuario',
                    in: 'query',
                    description: 'Id do usuário',
                    required: true,
                    type: 'string'
                  },
                  {
                    name: 'id_documento',
                    in: 'query',
                    description: 'Id do contrato',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/dados/administradora': {
              get: {
                tags: ['Administradora'],
                summary: 'Dados da Administradora',
                description: 'Dados da Administradora',
                operationId: 'dadosAdministradora',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    name: 'hash',
                    in: 'path',
                    description: 'Hash da Administradora',
                    required: true,
                    type: 'string'
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/entrar': {
              post: {
                tags: ['Contrato'],
                summary: 'Entrar no Contrato',
                description: 'Entrar no Contrato',
                operationId: 'entrarContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Entrar no Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/token': {
              get: {
                tags: ['Contrato'],
                summary: 'Token do Contrato',
                description: 'Token do Contrato',
                operationId: 'entrarContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Get token do Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/valida/token': {
              post: {
                tags: ['Contrato'],
                summary: 'Validar Token do Contrato',
                description: 'Validar Token do Contrato',
                operationId: 'validarTokenContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Validar Token do Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/email/error': {
              post: {
                tags: ['Notificações'],
                summary: 'Enviar Email de Erro',
                description: 'Enviar Email de Erro',
                operationId: 'enviarEmailErro',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Enviar Email de Erro',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/sms/error': {
              post: {
                tags: ['Notificações'],
                summary: 'Enviar SMS de Erro',
                description: 'Enviar SMS de Erro',
                operationId: 'enviarSMSErro',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Enviar SMS de Erro',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/unico/error': {
              post: {
                tags: ['Notificações'],
                summary: 'Enviar Unico de Erro',
                description: 'Enviar Unico de Erro',
                operationId: 'enviarUnicoErro',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Enviar Unico de Erro',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/pdf': {
              get: {
                tags: ['Contrato'],
                summary: 'Recuperar o PDF do Contrato',
                description: 'Recuperar o PDF do Contrato',
                operationId: 'recuperarPDFContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Recuperar o PDF do Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/registrar': {
              post: {
                tags: ['Contrato'],
                summary: 'Registrar Assinatura do Contrato',
                description: 'Registrar Assinatura do Contrato',
                operationId: 'registrarAssinaturaContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Registrar Assinatura do Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/contrato/error': {
              post: {
                tags: ['Contrato'],
                summary: 'Enviar Mensagem de Erro do Contrato',
                description: 'Enviar Mensagem de Erro do Contrato',
                operationId: 'enviarMensagemErroContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Enviar Mensagem de Erro do Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/visualizarpdf': {
              get: {
                tags: ['Contrato'],
                summary: 'Visualizar PDF Do Contrato Assinado',
                description: 'Visualizar PDF Do Contrato Assinado',
                operationId: 'vizualizarPDFContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Visualizar PDF Do Contrato Assinado',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/get': {
              get: {
                tags: ['Contrato'],
                summary: 'Download Arquivo',
                description: 'Download Arquivo',
                operationId: 'downloadArquivo',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Download Arquivo',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/get/logo': {
              get: {
                tags: ['Contrato'],
                summary: 'Download Arquivo Logo',
                description: 'Download Arquivo Logo',
                operationId: 'downloadArquivoLogo',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Download Arquivo Logo',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/visualizarlogassinatura': {
              get: {
                tags: ['Contrato'],
                summary: 'Visualizar Log Assinatura',
                description: 'Visualizar Log Assinatura',
                operationId: 'visualizarLogAssinatura',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Visualizar Log Assinatura',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/visualizarcontrato': {
              get: {
                tags: ['Contrato'],
                summary: 'Visualizar Contrato',
                description: 'Visualizar Contrato',
                operationId: 'visualizarContrato',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Visualizar Contrato',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            },
            '/aditivo/visualizar': {
              get: {
                tags: ['Contrato'],
                summary: 'Visualizar Aditivo',
                description: 'Visualizar Aditivo',
                operationId: 'visualizarAditivo',
                consumes: ['application/json'],
                produces: ['application/json'],
                parameters: [
                  {
                    in: 'body',
                    name: 'body',
                    description: 'Visualizar Aditivo',
                    required: true
                  }
                ],
                responses: {
                  200: {
                    description: 'OK'
                  },
                  400: {
                    description: 'Bad Request'
                  },
                  401: {
                    description: 'Unauthorized'
                  },
                  403: {
                    description: 'Forbidden'
                  },
                  404: {
                    description: 'Not Found'
                  },
                  500: {
                    description: 'Internal Server Error'
                  }
                }
              }
            }
          }
        }
      }
    },
    mounted () {
      SwaggerUI({
        dom_id: 'swagger-ui',
        spec: this.swaggerSpec
      })
      this.validate()
    },
    methods: {
      validate () {
        // prompt to password
        var password = prompt('Digite a senha para acessar a documentação', '')
        if (password === 'newm@2023') {
          // do nothing
        } else {
          // hide all content
          document.body.innerHTML = ''
          window.location.reload()
        }
      }
    }
  }
</script>

<style scoped>
h1 {
  text-align: center;
}
</style>
