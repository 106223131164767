<template>
  <v-dialog v-model="dialog" width="500" content-class="app-content" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" @click="getSignatarios">
        {{ title }}
      </v-list-item>
    </template>

    <v-card v-if="carregado" style="border-radius: 16px">
      <v-form ref="reenvio">
        <v-card-title class="text-h5">
          <v-flex class="d-flex">
            <v-flex>{{ title }}</v-flex>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="dialog = false">
                  <v-icon>fa fa-times</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-flex>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-flex class="font-weight-bold black--text mb-2">
              Signatários:
            </v-flex>

            <v-flex pa-1 v-if="apenasAssinados">
              <div v-for="(signatario) in signatarios" :key="signatario.nome">
                <v-flex v-if="!signatario.assinou">
                  <p class="mr-3 mb-3"> {{ abreviaSobrenome(signatario.nome) }} </p>
                  <p class="mr-3 mt-3 mb-3">Email:</p>
                  <m-text-field ref="textField" v-model="signatario.email"
                                :rules="[rules.required, rules.email]"
                                color="primary"
                                placeholder="Email"
                                style="top:-15px;"
                                dense
                                outlined />
                </v-flex>

                <v-flex>
                  <p class="mr-3 mb-3">Celular:</p>
                  <m-text-field v-model="signatario.celular"
                                placeholder="Celular"
                                style="top:-15px;"
                                validate-on-blur
                                v-mask="'(##) #####-####'"
                                :rules="['somente_sms', 'email_e_sms','email_ou_sms'].includes(tipoValidacao) ? [rules.required, rules.celular] : []"
                                return-masked-value
                                dense
                                outlined />
                </v-flex>
              </div>
            </v-flex>

            <v-flex pa-1 v-else>
              <div v-for="(signatario) in signatarios" :key="signatario.nome">
                <v-flex>
                  <p class="mr-3 mb-3"> {{ abreviaSobrenome(signatario.nome) }} </p>
                  <p class="mr-3 mt-3 mb-3">Email:</p>
                  <m-text-field ref="textField" v-model="signatario.email"
                                :rules="[rules.required, rules.email]"
                                color="primary"
                                placeholder="Email"
                                style="top:-15px"
                                dense
                                outlined />
                </v-flex>

                <v-flex>
                  <p class="mr-3 mb-3">Celular:</p>
                  <m-text-field v-model="signatario.celular"
                                placeholder="Celular"
                                style="top:-15px;"
                                validate-on-blur
                                v-mask="'(##) #####-####'"
                                :rules="['somente_sms', 'email_e_sms', 'email_ou_sms'].includes(tipoValidacao) ? [rules.required, rules.celular] : []"
                                return-masked-value
                                dense
                                outlined />
                </v-flex>
              </div>
            </v-flex>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-flex>
            <v-btn color="primary" class="button btn-default white--text"
                   @click="reenviaEmail">
              Reenviar
            </v-btn>
            <v-btn text color="primary" class="button btn-default white--text"
                   @click="dialog = false">
              Cancelar
            </v-btn>
          </v-flex>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
  import { alert, error } from '@/plugins/helpers'
  import { toDateBR, abreviaSobrenome } from '@/plugins/formatters'
  import Rules from '@/plugins/validation'

  export default {
    name: 'ReenvioEmailDialog',
    props: {
      hashContrato: String,
      title: String,
      url: String,
      apenasAssinados: Boolean,
      tipoValidacao: String
    },
    data: () => ({
      carregado: false,
      signatarios: {},
      dialog: false
    }),
    computed: {
      rules: () => Rules
    },
    watch: {
    },
    mounted () {
    },
    created () {
    },
    methods: {
      reenviaEmail () {
        let signatarios = null

        if (!this.$refs.reenvio.validate()) {
          return
        }

        if (this.apenasAssinados) {
          signatarios = Object.values(this.signatarios).filter(function (s) {
            return !s.assinou
          })
        } else { // Todos signatarios.
          signatarios = this.signatarios
        }

        this.$http.post(`${this.url}/${this.hashContrato}`, {
          signatarios: signatarios
        }).then(() => {
          alert('Contrato reenviado com sucesso!')
        }).catch(() => {
          error(null, 'Falha ao reenviar email!')
        })
      },
      getSignatarios () {
        this.carregado = false

        this.$http.get(`/signatarios/${this.hashContrato}`)
          .then((res) => {
            if (res.data) {
              this.signatarios = res.data
              this.carregado = true
            }
          })
          .catch(() => {
            error(null, 'Erro ao buscar signatários!')
            this.carregado = false
          })
      },
      toDateBR (val) {
        return val.includes('-') ? toDateBR(val) : val
      },
      abreviaSobrenome (val) {
        return abreviaSobrenome(val)
      }
    }
  }
</script>

<style scoped>

</style>
