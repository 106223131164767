<template>
  <v-combobox
      v-model="m_value"
      :filter="filter"
      :hide-no-data="!search"
      :items="m_items"
      :search-input.sync="search"
      hide-selected
      multiple
      small-chips
      outlined
      :rules="rules"
      validate-on-blur
      dense>
    <template v-slot:selection="{ attrs, item, parent, selected }">
      <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          :input-value="selected"
          label
          small>
        <span class="pa-2">
          {{ item.text.toUpperCase() }}
        </span>
        <v-icon
            small
            @click="parent.selectItem(item)">
          close
        </v-icon>
      </v-chip>
    </template>
    <template v-slot:item="{ index, item }">
      <v-text-field
          v-if="editing === item"
          v-model="editing.text"
          autofocus
          flat
          background-color="transparent"
          hide-details
          solo
          @keyup.enter="edit(index, item)"
      ></v-text-field>
      <v-chip
          v-else
          dark
          label
          small>
        {{ item.text.toUpperCase() }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-list-item-action @click.stop>
        <v-btn
            icon
            @click.stop.prevent="edit(index, item)">
          <v-icon>{{ editing !== item ? 'mdi-pencil' : 'mdi-check' }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </template>
  </v-combobox>
</template>

<script>
  export default {
    name: 'GrupoPlanoComboBox',
    props: { 'items': Array, 'value': [Array, Object, String, Number], 'rules': Array },
    data: () => ({
      activator: null,
      attach: null,
      editing: null,
      editingIndex: -1,
      m_items: [],
      menu: false,
      m_value: [],
      x: 0,
      search: null,
      y: 0
    }),
    watch: {
      value (val) {
        this.m_value = val
      },
      items (val) {
        this.m_items = val
      },
      m_value (val, prev) {
        if (val.length === prev.length) return
        this.m_value = val.map(v => {
          if (typeof v === 'string') {
            v = {
              text: v
            }
            if (v.text.match('^[a-zA-Z0-9_\\s]+$') === null) {
              return
            }

            if (this.m_value.includes(v)) {
              return
            }
            this.m_items.push(v)
          }
          return v
        })
        this.$emit('input', this.m_value)
      }
    },
    mounted () {
      this.m_items = this.items
      this.m_value = this.value
    },
    methods: {
      edit (index, item) {
        if (!this.editing) {
          this.editing = item
          this.editingIndex = index
        } else {
          this.editing = null
          this.editingIndex = -1
        }
      },
      filter (item, queryText, itemText) {
        if (item.header) return false

        const hasValue = val => val != null ? val : ''

        const text = hasValue(itemText)
        const query = hasValue(queryText)

        return text.toString()
          .toUpperCase()
          .indexOf(query.toString().toUpperCase()) > -1
      }
    }
  }
</script>

<style scoped>

</style>
