<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Aditivo contratual</h1>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout column mt-3 class="card">
        <v-form ref="aditivo">
          <v-flex mt-1 mb-4>
            <h3>Dados do Aditivo</h3>
            <hr>
          </v-flex>
          <v-flex pt-1 pl-10 pr-10>
            <v-row>
              <v-col cols="12">
                <label class="label-input">Nome do aditivo</label>
                <v-text-field placeholder="Nome do aditivo"
                              v-model="model.nome_aditivo"
                              :rules="[rules.required]"
                              v-input-filter="[filters.alphanumeric]"
                              maxlength="200"
                              outlined
                              dense />
              </v-col>
            </v-row>
          </v-flex>
          <v-flex pt-1 pl-10 pr-10 pb-5>
            <v-row>
              <v-col cols="12">
                <div v-if="model.id_aditivo > 0 && !model.enviar_pdf" class="mb-2">
                  <v-btn class="button btn-default mr-3" @click="dialog=true" outlined color="primary">Visualizar aditivo</v-btn>
                  <v-btn x-small class="button btn-default" @click="model.enviar_pdf=true" outlined>Alterar pdf</v-btn>
                </div>
                <div v-if="model.id_aditivo <= 0 || model.enviar_pdf">
                  <input type="file" id="file" ref="file" accept="application/pdf" @change="handleFileUpload()" />
                  <v-layout class="mt-4 mb-2">
                    <div style="padding-top: 1px;margin-right: 8px">
                      <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                    </div>
                    <div>
                      <div style="color: #797979;font-size: 10px">
                        <b>DICA PARA ENVIAR!</b><br>
                        O sistema só aceita arquivo do tipo <b>PDF</b> e com no máximo <b>15 MB</b>.
                      </div>
                    </div>
                  </v-layout>
                </div>
              </v-col>
            </v-row>
          </v-flex>
          <v-flex pt-1 pl-10 pr-10>
            <v-row>
              <v-col cols="12">
                <label class="label-input">Aplicar aditivo aos seguintes grupos:</label>
                <grupo-plano-combo-box :items.sync="items_grupos"
                                       v-model="model.grupos"
                                       min-length="0"
                                       outlined dense />
                <label class="label-input">Aplicar aditivo aos seguintes planos de venda:</label>
                <grupo-plano-combo-box :items.sync="items_planos"
                                       v-model="model.planos"
                                       min-length="0"
                                       outlined dense />
              </v-col>
            </v-row>
          </v-flex>
        </v-form>
        <v-flex pt-1 pl-10 pr-10>
          <v-switch v-model="model.ativo" :label="`${model.ativo ? 'Ativo' : 'Inativo'}`"></v-switch>
        </v-flex>
        <v-flex pr-10 pl-10>
          <v-row class="ma-0" justify="end">
            <v-btn color="primary" class="button btn-default white--text" @click="salvar">Salvar</v-btn>
          </v-row>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-row class="mt-0" justify="end">
      <v-dialog fullscreen v-model="dialog" content-class="app-content">
        <v-card style="border-radius: 16px">
          <v-card-title class="text-h5">
            <v-flex class="d-flex">
              <v-flex>Aditivo</v-flex>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="dialog = false">
                    <v-icon>fa fa-times</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </v-card-title>
          <v-card-text>
            <v-flex>
              <iframe style="border-radius: 16px" :src="`${ws}/aditivo/visualizar/${administradora.id_administradora}/${model.id_aditivo}`" width="100%" height="640px" />
            </v-flex>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import Rules from '@/plugins/validation'
  import Filters from '@/plugins/filters'
  import GrupoPlanoComboBox from './components/GrupoPlanoComboBox'
  import { alert, error } from '../../plugins/helpers'

  export default {
    name: 'CadastrarAditivoContratual',
    components: { GrupoPlanoComboBox },
    data: () => ({
      administradora: {},
      nome_aditivo: '',
      items_grupos: [],
      items_planos: [],
      dialog: false,
      model: {
        id_aditivo: 0,
        nome_aditivo: '',
        grupos: [],
        planos: [],
        ativo: true,
        enviar_pdf: true
      }
    }),
    computed: {
      rules: () => Rules,
      filters: () => Filters
    },
    mounted () {
      this.administradora = this.$session.get('administradora')
      const id_aditivo = parseInt(this.$route.params['id_aditivo'])
      if (id_aditivo > 0) {
        this.model.enviar_pdf = false
        this.model.id_aditivo = id_aditivo
        this.$http.get(`/admin/aditivo/${id_aditivo}`)
          .then((r) => {
            this.model.id_aditivo = id_aditivo
            this.model.nome_aditivo = r.data.aditivo.nome_aditivo
            r.data.grupos.forEach((grupo) => { this.model.grupos.push(grupo) })
            r.data.planos.forEach((plano) => { this.model.planos.push(plano) })
            this.model.ativo = r.data.aditivo.ativo
          })
          .catch(() => error(null, 'Falha ao remover aditivo, tente novamente.'))
      }
    },
    methods: {
      handleFileUpload () {
        this.file = this.$refs.file.files[0]
      },
      salvar () {
        if (this.$refs.aditivo.validate()) {
          if (this.file && this.file.size > 15728640) {
            error(null, 'O PDF não pode ter mais do que 15 MB.')
            return
          }
          let formData = new FormData()
          formData.append('file', this.file)
          formData.append('aditivo', JSON.stringify(this.model))
          this.$http.post(
            `/admin/aditivo/salvar/${this.administradora.id_administradora}`,
            formData,
            {
              headers: { 'Content-Type': 'multipart/form-data' }
            }).then((r) => {
            if (r.data.ok) {
              alert({ text: r.data.msg, buttons: { 'Continuar': () => { this.$router.push({ name: 'aditivo_contratual' }) } } })
            } else {
              error(null, r.data.msg_erro)
            }
          }).catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
        }
      }
    }
  }
</script>

<style scoped>
.v-btn.btn-default:not(.shrink) {
  width: 240px !important;
}

</style>
