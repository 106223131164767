import moment from 'moment'
import { unmask } from './helpers'

export default {
  required: v => !!(v && String(v).trim()) || 'Campo obrigatório',
  selected: v => (v !== null && v !== undefined) || 'Selecione uma opção',
  time: v => !v || !!(v && moment(v, 'hh:mm', true).isValid()) || 'Hora inválida',
  date: v => !v || !!(v && moment(v, 'DD/MM/YYYY', true).isValid()) || 'Data inválida',
  datetime: v => !v || !!(v && moment(v, 'DD/MM/YYYY HH:mm', true).isValid()) || 'Data ou hora inválida',
  notzero: v => !v || v > 0 || 'O valor deve ser maior que 0',
  dataFutura: v => !v || !(v && moment(v, 'DD/MM/YYYY').isBefore(moment())) || 'A data não pode ser menor que a data atual',
  dataMinima16: v => !v || !(v && moment(v, 'DD/MM/YYYY').isAfter(moment(minimumAge(16)))) || 'A data não pode ser maior que ' + moment(minimumAge(16)).format('DD/MM/YYYY'),
  dataMaxima150: v => !v || !(v && moment(v, 'DD/MM/YYYY').isBefore(moment(maximumAge(150)))) || 'A data não pode ser menor que ' + moment(maximumAge(150)).format('DD/MM/YYYY'),
  dataMaxima500: v => !v || !(v && moment(v, 'DD/MM/YYYY').isBefore(moment(maximumAge(500)))) || 'A data não pode ser menor que ' + moment(maximumAge(500)).format('DD/MM/YYYY'),
  dataPassada: v => !v || !(v && moment(v, 'DD/MM/YYYY').isAfter(moment())) || 'A data não pode ser maior que a data atual',
  dataHoraFutura: v => !v || !(v && moment(v, 'DD/MM/YYYY HH:mm').isBefore(moment())) || 'A data não pode ser menor que a data e hora atual',
  dataHoraPassada: v => !v || !(v && moment(v, 'DD/MM/YYYY HH:mm').isAfter(moment())) || 'A data não pode ser maior que a data e hora atual',
  url: v => !v || (/^(ftp|http|https):\/\/[^ "]+$/.test(v)) || 'O link precisa ser uma URL. Verifique se começa com \'http://\' ou \'https://\'',
  telefone: v => !v || /^\([1-9]{2}\) [2-8][0-9]{3}-[0-9]{4}$/.test(v) || 'Telefone inválido!',
  celular: v => !v || /^\([1-9]{2}\) 9[0-9][0-9]{3}-[0-9]{4}$/.test(v) || 'Celular inválido!',
  email: v => !v || /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i.test(v) || 'E-mail inválido!',
  cpf: v => !v || (v && validaCPF(v)) || 'CPF inválido!',
  cnpj: v => !v || (v && validaCNPJ(v)) || 'CNPJ inválido!',
  cpf_cnpj: v => !v || (v.length <= 14 ? (validaCPF(v) || 'CPF inválido!') : (validaCNPJ(v) || 'CNPJ inválido!')),
  cep: v => !v || (v && /^[0-9]{5}-[0-9]{3}$/.test(v)) || 'CEP inválido!',
  texto: v => !v || (/^[a-zA-Z\u00C0-\u017F\s]+$/.test(v)) || 'Não é permitido caracteres especiais'
}

export function minimumAge (age) {
  let date = new Date()
  date.setFullYear(date.getFullYear() - age)
  return date.toISOString().substr(0, 10)
}

export function maximumAge (age) {
  let date = new Date()
  date.setFullYear(date.getFullYear() - age + 1)
  date.setDate(date.getDate() + 1)
  return date.toISOString().substr(0, 10)
}

export function validaCPF (cpf) {
  cpf = unmask(cpf)
  let numeros, digitos, soma, i, resultado, digitos_iguais
  digitos_iguais = 1
  if (cpf.length < 11) {
    return false
  }
  for (i = 0; i < cpf.length - 1; i++) {
    if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
      digitos_iguais = 0
      break
    }
  }
  if (!digitos_iguais) {
    numeros = cpf.substring(0, 9)
    digitos = cpf.substring(9)
    soma = 0
    for (i = 10; i > 1; i--) {
      soma += numeros.charAt(10 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    if (resultado != digitos.charAt(0)) {
      return false
    }
    numeros = cpf.substring(0, 10)
    soma = 0
    for (i = 11; i > 1; i--) {
      soma += numeros.charAt(11 - i) * i
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
    // eslint-disable-next-line eqeqeq
    return resultado == digitos.charAt(1)
  }
  return false
}

export function validaCNPJ (cnpj) {
  cnpj = unmask(cnpj)

  if (cnpj === '') return false

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs invalidos conhecidos
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999'
  ].includes(cnpj)) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  if (resultado != digitos.charAt(0)) {
    return false
  }

  tamanho = tamanho + 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  resultado = soma % 11 < 2 ? 0 : 11 - soma % 11
  // eslint-disable-next-line eqeqeq
  return resultado == digitos.charAt(1)
}
