<template>
  <v-dialog v-model="dialog" width="800" content-class="app-content" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on" @click="getSignatarios">
        Buscar contrato no Newcon
      </v-list-item>
    </template>

    <v-card v-if="carregado" style="border-radius: 16px">
      <v-card-title class="text-h5">
        <v-flex class="d-flex">
          <v-flex><strong>Buscar contrato no Newcon</strong></v-flex>
          <v-menu bottom left>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" icon @click="dialog = false">
                <v-icon>fa fa-times</v-icon>
              </v-btn>
            </template>
          </v-menu>
        </v-flex>
      </v-card-title>

      <v-card-text pa-4>
        <v-container>
          <v-row>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Identificador do documento
              </v-row>
              {{ contrato.id_documento }}
            </v-col>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Identificador da empresa
              </v-row>
              {{ contrato.id_empresa }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Identificador do tipo de documento
              </v-row>
              {{ contrato.id_tipo_documento }}
            </v-col>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Valor do bem
              </v-row>
              {{ contrato.vl_bem | money }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Nome do bem
              </v-row>
              {{ contrato.nm_bem }}
            </v-col>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Valor da parcela inicial
              </v-row>
              {{ contrato.vl_parcela_incial | money }}
            </v-col>
            <v-col>
              <v-row class="font-weight-bold black--text mb-1">
                Número de parcelas
              </v-row>
              {{ contrato.no_parcelas }}
            </v-col>
          </v-row>

          <v-row class="font-weight-bold black--text mb-1">
            Signatários:
          </v-row>

          <v-row v-for="(signatario) in signatarios" :key="signatario[0]">
            <v-row class="mb-2 pr-1 pl-1">
              <v-col class="sig">
                <v-flex class="font-weight-bold sig">
                  Nome
                </v-flex>
                {{ abreviaSobrenome(signatario.nome) }}
              </v-col>
              <v-col class="sig">
                <v-flex class="font-weight-bold sig">
                  CPF
                </v-flex>
                {{ signatario.cpf }}
              </v-col>
              <v-col class="sig">
                <v-flex class="font-weight-bold sig">
                  E-mail
                </v-flex>
                {{ signatario.email }}
              </v-col>
              <v-col class="sig">
                <v-flex class="font-weight-bold sig">
                  Data de nascimento
                </v-flex>
                {{ toDateBR(signatario.dt_nascimento) }}
              </v-col>
            </v-row>
          </v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-flex>
          <v-btn color="primary" class="button btn-default white--text"
                 @click="$emit('buttonClick')">
            Buscar contrato Newcon
          </v-btn>
          <v-btn text color="primary" class="button btn-default white--text"
                 @click="dialog = false">
            Cancelar
          </v-btn>
        </v-flex>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import { error } from '@/plugins/helpers'
  import { toDateBR, abreviaSobrenome } from '@/plugins/formatters'

  export default {
    name: 'DetalhesContratoDialog',
    props: {
      hashContrato: String,
      contrato: { type: Object }
    },
    data: () => ({
      carregado: false,
      signatarios: {},
      dialog: false
    }),
    methods: {
      getSignatarios () {
        this.carregado = false

        this.$http.get(`/signatarios/${this.contrato.hash_contrato}`)
          .then((res) => {
            if (res.data) {
              this.signatarios = res.data
              this.carregado = true
            }
          })
          .catch(() => {
            error(null, 'Erro ao buscar signatários!')
            this.carregado = false
          })
      },
      toDateBR (val) {
        return val.includes('-') ? toDateBR(val) : val
      },
      abreviaSobrenome (val) {
        return abreviaSobrenome(val)
      }
    }
  }
</script>

<style scoped>

  .sig {
    font-size: 12px;
    margin-bottom: 1px;
  }

</style>
