import { render, staticRenderFns } from "./DataTable.vue?vue&type=template&id=0b64228d&scoped=true&"
import script from "./DataTable.vue?vue&type=script&lang=js&"
export * from "./DataTable.vue?vue&type=script&lang=js&"
import style0 from "./DataTable.vue?vue&type=style&index=0&id=0b64228d&lang=scss&scoped=true&"
import style1 from "./DataTable.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b64228d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VDataTable,VLayout,VProgressCircular,VRow})
