import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import storage from './storage'
import { pick } from 'lodash'

Vue.use(Vuex)

const key = process.env.VUE_APP_SECURED ? 'ksPGZ2gAg8ZTrtlRX3GeSP1hhUSSTAbl' : 'assinatura_consorcio'

const vuexLocal = new VuexPersistence({
  key,
  storage,
  reducer (state) {
    return pick(state, Object.keys(persistentes))
  }
})

const persistentes = {
  senha_invalida: false,
  token: '',
  administradora: {}
}

const nao_persistentes = {
  loading: false,
  alert: { visible: false, options: null }
}

function defaultState () {
  return {
    ...persistentes,
    ...nao_persistentes
  }
}

const state = defaultState()

const getters = {
}

let loading = 0
let alertStack = []

const mutations = {
  destroy () {
    store.replaceState(defaultState())
  },
  setState (state, data) {
    Object.assign(state, data)
  },
  setAutenticar (state, promise) {
    if (promise) {
      state.autenticar = true
      state.senha_invalida = !!promise.senha_invalida
      state.retry_resolvers.push(promise)
    } else {
      state.autenticar = false
      state.senha_invalida = false
      state.retry_resolvers = []
    }
  },
  loadingStart (state) {
    state.loading = !!++loading
  },
  loadingDone (state) {
    if (loading > 0) {
      state.loading = !!--loading
    }
  },
  alertShow (state, options) {
    if (state.alert.visible) {
      alertStack.push(options)
    } else {
      state.alert = { options, visible: true }
    }
  },
  alertHide (state) {
    state.alert.visible = false
    if (alertStack.length) {
      setTimeout(() => {
        store.commit('alertShow', alertStack.shift())
      }, 150)
    }
  }
}

const actions = {}

const plugins = [ vuexLocal.plugin ]

const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  plugins
})

export default store
