<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Contratos</h1>
          </v-flex>
        </v-layout>
      </v-flex>
      <!--  BUTTONS  -->
      <v-row class="mt-5" justify="end">
        <v-btn class="ma-2 home-btn" outlined color="primary" @click="irParaRelatorio">
          <v-icon>mdi-download-outline</v-icon>
          <p>RELATÓRIO DE ASSINATURAS</p>
        </v-btn>
        <v-btn class="ma-2 home-btn" dark color="primary" :to="{name:'cadastro_contrato'}">
          <v-icon>mdi-plus</v-icon>
          <p>NOVO CONTRATO</p>
        </v-btn>
      </v-row>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout mt-3 class="row">
        <!--  TABS  -->
        <ul class="home-tabs group row">
          <home-tab :text="'Pendentes de Download'" :icon="'mdi-file-download-outline'" :counter="tabs.pen_download" :active="true" @change="mudaLista(1)" />
          <home-tab :text="'Pendentes de Assinatura'" :icon="'mdi-file-edit-outline'" :counter="tabs.pen_assinatura" @change="mudaLista(2)" />
          <home-tab :text="'Contratos Assinados'" :icon="'mdi-file-check-outline'" :counter="tabs.assinados" @change="mudaLista(3)" />
        </ul>

        <!--  TABLE  -->
        <v-layout column class="card table-home">
          <v-flex>
            <v-row class="mt-1 ma-1">
              <v-menu content-class="menu-card mt-1 app-content"
                      min-width="110px"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      offset-y
                      v-model="popupFiltro">
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" text class="v-btn filtrar" color="primary" @click="popupFiltro = true">
                    <v-icon left>icon-filter</v-icon>
                    Filtrar
                  </v-btn>
                </template>
                <v-container class="filtro-div">
                  <v-flex>
                    <v-row>
                      <v-col>
                        <label class="label-input">Valor do bem</label>
                        <MoneyTextField color="primary"
                                        v-model="dataTableFiltroDefault.vl_bem"
                                        placeholder="Valor do bem"
                                        :options="{ locale: 'pt-BR', suffix: '', precision: 2, prefix: '' }"
                                        dense
                                        outlined />
                      </v-col>
                      <v-col>
                        <label class="label-input">Nome do bem</label>
                        <v-text-field color="primary"
                                      v-model="dataTableFiltroDefault.nm_bem"
                                      placeholder="Nome do bem"
                                      dense
                                      outlined />
                      </v-col>
                    </v-row>

                    <v-row class="mt-n5">
                      <v-col>
                        <label class="label-input">Data de cadastro</label>
                        <DateField placeholder="Data de cadastro"
                                   v-model="dataTableFiltroDefault.data_hora_criacao"
                                   dense
                                   validate-on-blur v-mask="'##/##/####'"
                                   return-masked-value
                                   outlined
                                   :rules="[]" />
                      </v-col>
                      <v-col>
                        <label class="label-input">Data limite</label>
                        <DateField color="primary"
                                   v-model="dataTableFiltroDefault.dt_expiracao"
                                   placeholder="Data limite"
                                   dense
                                   outlined
                                   :rules="[]" />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <label class="label-input">Nome/razão social do signatário</label>
                        <v-text-field v-model="dataTableFiltroDefault.nome_signatario"
                                      placeholder="Nome do signatário"
                                      dense
                                      outlined
                                      v-input-filter="[filters.text]" />
                      </v-col>
                      <v-col>
                        <label class="label-input">CPF / CNPJ</label>
                        <v-text-field v-model="dataTableFiltroDefault.cpf_signatario"
                                      validate-on-blur v-mask="['###.###.###-##', '##.###.###/####-##']"
                                      placeholder="CPF/CNPJ"
                                      dense
                                      outlined />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <label class="label-input">Número do contrato</label>
                        <v-text-field v-model="dataTableFiltroDefault.numero_documento"
                                      placeholder="Número do contrato"
                                      dense
                                      outlined />
                      </v-col>
                    </v-row>
                    <v-flex mb-4>
                      <label class="label-input">Origem</label>
                      <v-row wrap>
                        <v-flex mr-3>
                          <v-checkbox v-model="dataTableFiltroDefault.origem"
                                      :label="origens.manual_newcon.descricao"
                                      :value="origens.manual_newcon.id" />
                        </v-flex>
                        <v-flex mr-3>
                          <v-checkbox v-model="dataTableFiltroDefault.origem"
                                      :label="origens.manual_upload.descricao"
                                      :value="origens.manual_upload.id" />
                        </v-flex>
                        <v-flex ml-3>
                          <v-checkbox v-model="dataTableFiltroDefault.origem"
                                      :label="origens.afv_newm.descricao"
                                      :value="origens.afv_newm.id" />
                        </v-flex>
                        <v-flex ml-3>
                          <v-checkbox v-model="dataTableFiltroDefault.origem"
                                      :label="origens.newcon.descricao"
                                      :value="origens.newcon.id" />
                        </v-flex>
                      </v-row>
                    </v-flex>
                  </v-flex>
                  <v-row v-if="abaAtiva === 3">
                    <v-col>
                      <label class="label-input">Status</label>
                      <v-checkbox v-model="dataTableFiltroDefault.arquivado"
                                  :label="'Arquivado'"
                                  :value="false" />
                    </v-col>
                  </v-row>
                  <v-flex class="d-flex justify-center">
                    <v-btn color="primary" outlined class="v-btn btn-filtar mr-2" @click="limpar">Limpar</v-btn>
                    <v-btn color="primary" class="v-btn btn-filtar" @click="actionFilter">Filtrar</v-btn>
                  </v-flex>
                </v-container>
              </v-menu>

              <v-row class="mt-5" justify="end">
                <v-btn v-if="showCancellCsvFilter" class="ma-2 home-btn" color="primary" @click="cancellCsvImport">
                  <p>Limpar seleção em lote</p>
                </v-btn>
                <v-btn class="ma-2 home-btn" dark color="primary" @click="openDialogImportCSV">
                  <p>selecionar em Lote</p>
                </v-btn>
              </v-row>
            </v-row>

            <!-- BUTTONS CHECKBOX -->

            <v-row v-if="abaAtiva === 1 && itemSelectedExits" class="mt-5" justify="end">
              <v-btn class="ma-2 home-btn" dark color="primary" @click="getMultipleNewConContract">
                <p>Buscar Contrato(s) no Newcon</p>
              </v-btn>

              <v-btn class="ma-2 home-btn" dark color="primary" @click="deleteMultipleContract">
                <p>DELETAR CONTRATO(S)</p>
              </v-btn>
            </v-row>

            <v-row v-if="abaAtiva === 2 && itemSelectedExits" class="mt-5" justify="end">
              <v-btn class="ma-2 home-btn" dark color="primary" @click="extendDeadlineMultiple">
                <p>Prorrogar Data Limite</p>
              </v-btn>

              <v-btn class="ma-2 home-btn" dark color="primary" @click="resendSignatureRequestMultiple">
                <p>Reenviar Solicitação(ões) de Assinatura(s)</p>
              </v-btn>

              <v-btn class="ma-2 home-btn" dark color="primary" @click="cancelMultiple">
                <p>Cancelar</p>
              </v-btn>
            </v-row>

            <v-row v-if="abaAtiva === 3 && itemSelectedExits" class="mt-5" justify="end">
              <v-btn class="ma-2 home-btn" dark color="primary" @click="resendConfirmationEmailMultiple">
                <p>Reenviar E-mail(s) de Confirmação</p>
              </v-btn>

              <v-btn class="ma-2 home-btn" dark color="primary" @click="arquivarMultipleContract">
                <p>Arquivar Contrato(s)</p>
              </v-btn>
            </v-row>
          </v-flex>

          <DataTable mt-2 ref="datatable" :url="url"
                     class="pt-2 data-table-overlow"
                     :headers="headers"
                     :show-select="showSelect"
                     :params="params"
                     :filter="dataTableFiltro"
                     :silent="true"
                     :item-key="'id_documento'"
                     v-model="selectedItens"
                     @totalItems="totalHomeTab"
                     :force-select-all="forceSelectAll">
            <template v-slot:items="item">
              <td>
                {{ item.id_documento }}
              </td>
              <td>
                {{ money(item.vl_bem) }}
              </td>
              <td>
                {{ item.nm_bem }}
              </td>
              <td>
                <!-- LABELS DE ATÉ 3 SIGNATARIOS -->
                <div class="d-inline" v-for="(signatario, i) in item.signatarios" :key="signatario.id_signatario">
                  <div v-if="i < 3" :class="signatario.assinou ? 'signatario assinado' : 'signatario'">
                    {{ abreviaSobrenome(signatario.nome) }}
                    <br />
                    {{ signatario.cpf | cpf }}
                  </div>
                </div>

                <!-- TOOLTIP DOS DEMAIS SIGNATARIOS (CASO HAJA 3+) -->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }"
                            v-if="Object.keys(item.signatarios).length > 3"
                            class="signatarios-restantes">
                    <span v-bind="attrs" v-on="on">
                      {{ '+' + (Object.keys(item.signatarios).length - 3) + ' outros' }}
                    </span>
                  </template>

                  <div v-for="(signatario, i) in item.signatarios" :key="signatario.id_signatario">
                    <div v-if="i >= 3">
                      <div :class="signatario.assinou ? 'assinou' : 'pendente'"></div>
                      <p> {{ abreviaSobrenome(signatario.nome) }} </p>
                      <v-divider style="border-color:white !important"></v-divider>
                    </div>
                  </div>
                  <div class="arrow-down"></div>
                </v-tooltip>
              </td>
              <td>
                {{ toDateBR(item.data_hora_criacao) }}
              </td>
              <td>
                {{ toDateBR(item.dt_expiracao) }}
              </td>
              <td>
                {{ getDescricaoNormalizada(item.origem) }}
              </td>
              <td>
                <!-- MENU DE CONTEXTO -->
                <v-flex>
                  <v-menu origin="center center"
                          top
                          left
                          :offset-x="false"
                          :offset-y="true"
                          class="transparent"
                          transition="slide-y-transition">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn-list" elevation="0" color="transparent" v-bind="attrs" v-on="on">
                        ...
                      </v-btn>
                    </template>
                    <v-list class="tooltip menu-balloon">
                      <!-- OPCOES PENDENTE DE DOWNLOAD -->
                      <DetalhesContratoDialog v-if="abaAtiva === 1" :contrato="item" @buttonClick="buscarContratoNewcon(item)" />
                      <v-divider v-if="abaAtiva === 1" style="border-color:white !important"></v-divider>
                      <v-list-item v-if="abaAtiva === 1" @click="deletarContrato(item)">Deletar contrato</v-list-item>

                      <!-- OPCOES PENDENTE DE ASSINATURA OU CONTRATOS ASSINADOS -->
                      <v-list-item v-if="abaAtiva === 2" @click="visualizarContrato(item)">
                        Visualizar contrato
                      </v-list-item>
                      <v-divider v-if="abaAtiva !== 1" style="border-color:white !important"></v-divider>

                      <!-- OPCOES PENDENTE DE ASSINATURA -->
                      <ReenvioEmailDialog v-if="abaAtiva === 2"
                                          :hash-contrato="item.hash_contrato"
                                          :title="'Reenviar solicitação de assinatura'"
                                          :url="'/reenviarsolicitacaoassinatura'"
                                          :tipo-validacao="configuracao_adm.tipo_validacao"
                                          :apenas-assinados="true" />
                      <v-divider v-if="abaAtiva === 2" style="border-color:white !important"></v-divider>

                      <v-list-item v-if="abaAtiva === 2" @click="cancelar(item)">
                        Cancelar
                      </v-list-item>

                      <!-- OPCOES CONTRATOS ASSINADOS -->
                      <v-list-item v-if="abaAtiva === 3" @click="visualizarContratoAssinado(item)">
                        Visualizar contrato assinado
                      </v-list-item>

                      <v-divider v-if="abaAtiva !== 2 && abaAtiva !== 1" style="border-color:white !important"></v-divider>

                      <ReenvioEmailDialog v-if="abaAtiva === 3"
                                          :hash-contrato="item.hash_contrato"
                                          :title="'Reenviar e-mail de confirmação'"
                                          :url="'/reenviaremailfinal'" />

                      <v-divider v-if="abaAtiva !== 2 && abaAtiva !== 1" style="border-color:white !important"></v-divider>

                      <v-list-item v-if="abaAtiva === 3" @click="arquivarContrato(item)">Arquivar contrato</v-list-item>
                    </v-list>
                    <div class="arrow-down-right"></div>
                  </v-menu>
                </v-flex>
              </td>
            </template>
          </DataTable>
        </v-layout>
      </v-layout>
    </v-layout>

    <ImportCSVDialog
        :dialog="showPopupImportCSV"
        :status="abaAtiva"
        @close-dialog-import="closeDialogImportCSV"
        @close-import="dialogImportCSV" />
    <ProrrogarDataLimiteDialog
        :contratos="paramsMultipleConstructorComputed"
        :dialog="showPopupProrrogar"
        @close-dialog="closeDialogExtendDeadline" />
  </v-container>
</template>

<script>
  import { abreviaSobrenome, money, toDateBR } from '@/plugins/formatters'
  import { alert, error } from '@/plugins/helpers'
  import HomeTab from './components/HomeTab'
  import DataTable from '../../components/DataTable'
  import DateField from '../../components/DateField'
  import MoneyTextField from '../../components/MoneyTextField'
  import DetalhesContratoDialog from './components/DetalhesContratoDialog'
  import ReenvioEmailDialog from './components/ReenvioEmailDialog'
  import ImportCSVDialog from './components/ImportCSVDialog'
  import ProrrogarDataLimiteDialog from './components/ProrrogarDataLimiteDialog'
  import Filters from '../../plugins/filters'
  import { Origens } from '../../constantes'

  export default {
    name: 'Home',
    components: { ReenvioEmailDialog, DetalhesContratoDialog, HomeTab, DataTable, DateField, MoneyTextField, ProrrogarDataLimiteDialog, ImportCSVDialog },
    data: () => ({
      url: '/listarpendentesdownload',
      administradora: {},
      popupFiltro: false,
      abaAtiva: 1,
      dataTableFiltroDefault: {
        vl_bem: '',
        nm_bem: '',
        data_hora_criacao: '',
        dt_expiracao: '',
        nome_signatario: '',
        cpf_signatario: '',
        numero_documento: '',
        origem: [],
        arquivado: false,
        csvFilter: []
      },
      showSelect: true,
      dataTableFiltro: {},
      dataTableOptions: { silent: true },
      headers: [
        {
          text: 'Proposta #',
          align: 'start',
          value: 'id_documento'
        },
        { text: 'Valor do bem', value: 'vl_bem' },
        { text: 'Nome do bem', value: 'nm_bem' },
        { text: 'Nome dos Signatários', value: '', sortable: false },
        { text: 'Data de Cadastro', value: 'data_hora_criacao' },
        { text: 'Data Limite', value: 'dt_expiracao' },
        { text: 'Origem', value: 'origem' },
        {
          text: 'Ações',
          align: 'end',
          value: '',
          sortable: false
        }
      ],
      pagination: {
        sortBy: [ 'id_documento' ], descending: [ true ], rowsPerPage: 15
      },
      tabs: {
        pen_download: 0,
        pen_assinatura: 0,
        assinados: 0
      },
      configuracao_adm: {
        tipo_validacao: ''
      },
      data_hora_criacao_status: '',
      event_source: {},
      origens: Origens,
      selectedItens: [],
      showPopupProrrogar: false,
      showPopupImportCSV: false,
      showCancellCsvFilter: false,
      forceSelectAll: false
    }),
    computed: {
      filters: () => Filters,
      params () {
        return {
          pagination: this.pagination,
          id_administradora: this.administradora.id_administradora
        }
      },
      itemSelectedExits () {
        return this.selectedItens.length > 0
      },
      paramsMultipleConstructorComputed () {
        return this.selectedItens.map(obj => obj.id_contrato)
      }
    },
    created () {
      this.administradora = this.$session.get('administradora')
      this.$http.get(`/contrato/status/criacao/${this.administradora.id_administradora}`)
        .then((r) => {
          this.data_hora_criacao_status = r.data
        })
    },
    mounted () {
      this.getQuantidadeContratoPorAba()
      this.getTipoValidacao()
      this.registrarEventSource()
    },
    beforeDestroy () {
      this.event_source.close()
    },
    methods: {
      destroy () {
        this.$destroy()
      },
      registrarEventSource () {
        let url_event = `${this.ws}/verificar/status/atualizacao?administradora=${this.administradora.id_administradora}`
        this.event_source = new EventSource(url_event)

        this.event_source.addEventListener('message', event => {
          if (typeof event.data !== 'undefined') {
            if (event.data !== this.data_hora_criacao_status) {
              this.data_hora_criacao_status = event.data
              this.getQuantidadeContratoPorAba()
              this.dataTableFiltro = { ...this.dataTableFiltroDefault }
              this.dataTableFiltro = { ...this.dataTableFiltro }
            }
          }
        }, false)

        this.event_source.addEventListener('error', event => {
          if (event.readyState === EventSource.CLOSED) {
            console.info('Event was closed')
            console.info(EventSource)
          }
        }, false)
      },
      getQuantidadeContratoPorAba () {
        this.$http.get(`/contrato/quantidades/${this.administradora.id_administradora}`, { options: { silent: true } })
          .then((r) => { this.tabs = r.data.tabs })
          .catch(() => {
            error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.')
          })
      },
      totalHomeTab (value) {
        switch (this.abaAtiva) {
          case 1:
            this.tabs.pen_download = value
            break
          case 2:
            this.tabs.pen_assinatura = value
            break
          case 3:
            this.tabs.assinados = value
            break
        }
      },
      actionFilter () {
        this.popupFiltro = false
        this.cancellCsvImport()
        this.filtrar()
      },
      filtrar () {
        this.dataTableFiltro = { ...this.dataTableFiltroDefault }
        this.dataTableFiltro = { ...this.dataTableFiltro }
      },
      limpar () {
        this.dataTableFiltroDefault = {
          vl_bem: '',
          nm_bem: '',
          data_hora_criacao: '',
          dt_expiracao: '',
          origem: [],
          csvFilter: [],
          arquivado: false
        }
        this.dataTableFiltro = this.dataTableFiltroDefault
        this.popupFiltro = false
        this.showCancellCsvFilter = false
        this.forceSelectAll = false
        this.filtrar()
      },
      mudaLista (tabID) {
        this.abaAtiva = tabID
        switch (tabID) {
          case 1:
            this.url = '/listarpendentesdownload'
            break
          case 2:
            this.url = '/listarpendentesassinatura'
            break
          case 3:
            this.url = '/listarcontratosassinados'
            break
        }
        this.alterList()
      },
      alterList () {
        this.cancellCsvImport()
        this.dataTableFiltro.arquivado = false
        this.dataTableFiltroDefault.arquivado = false
      },
      buscarContratoNewcon (item) {
        this.$http.post(`/buscacontrato/${item.id_contrato}`).then(() => {
          alert('Contrato direcionado para download!')
        }).catch(() => {
          error(null, 'Falha ao gerar contrato PDF! Tente novamente ou acesse a tela Cadastro manual de contrato para emissão manualmente.')
        })
      },
      visualizarContrato (item) {
        let w = window.open(`${this.ws}/visualizarcontrato/${item.hash_contrato}`)
        w.document.title = 'Assinatura consórcio'
      },
      visualizarContratoAssinado (item) {
        let w = window.open(`${this.ws}/visualizarpdf/${item.hash_documento_final}`)
        w.document.title = 'Assinatura consórcio'
      },
      cancelar (item) {
        // noinspection JSNonASCIINames,NonAsciiCharacters
        alert({
          title: 'Atenção!',
          text: `Confirma o cancelamento do contrato ${item.id_documento}? Após cancelado não será mais permitido assinatura de nenhum signatário.`,
          img_topo: false,
          buttons: {
            'Sim': () => {
              this.$http.post(`/cancelarcontrato/${item.hash_contrato}`)
                .then(() => {
                  alert(`Contrato ${item.id_documento} cancelado com sucesso!`)
                  this.filtrar()
                })
                .catch(() => {
                  error(null, 'Falha ao cancelar contrato! Tente novamente.')
                  this.filtrar()
                })
            },
            'Não': () => true
          }
        })
      },
      deletarContrato (item) {
        alert({
          title: 'Atenção',
          text: `Confirma a exclusão do contrato ${item.id_documento}? Esta ação não poderá ser desfeita!`,
          img_topo: false,
          buttons: {
            'Sim': () => {
              this.$http.delete(`/deletacontrato/${item.id_contrato}`)
                .then(() => {
                  alert(`Contrato deletado com sucesso!`)
                  this.filtrar()
                })
                .catch(() => {
                  error(null, 'Falha ao deletar contrato! Tente novamente.')
                  this.filtrar()
                })
            },
            'Não': () => true
          }
        })
      },
      arquivarContrato (item) {
        alert({
          title: 'Atenção',
          text: `Confirma o arquivamento do contrato ${item.id_documento}? Esta ação não poderá ser desfeita!`,
          img_topo: false,
          buttons: {
            'Sim': () => {
              this.$http.post(`/arquivacontrato/${item.id_contrato}`)
                .then(() => {
                  alert(`Contrato arquivado com sucesso!`)
                  this.filtrar()
                })
                .catch(() => {
                  error(null, 'Falha ao arquivar contrato! Tente novamente.')
                  this.filtrar()
                })
            },
            'Não': () => true
          }
        })
      },
      irParaRelatorio () {
        this.$router.replace({ name: 'relatorioassinaturas' })
      },
      visualizarLog (item) {
        window.open(`${this.ws}/visualizarlogassinatura/${item.hash_contrato}`)
      },
      toDateBR (val) {
        return val.includes('-') ? toDateBR(val) : val
      },
      abreviaSobrenome (val) {
        return abreviaSobrenome(val)
      },
      money (val) {
        return money(val)
      },
      getTipoValidacao () {
        if (!this.configuracao_adm.tipo_validacao) {
          this.$http.get(`/admin/parametrizacao/${this.administradora.id_administradora}`)
            .then((r) => {
              this.configuracao_adm.tipo_validacao = r.data.administradora.tipo_validacao
            })
        }
      },
      getDescricaoNormalizada (origem) {
        let descricao = ''
        Object.entries(this.origens).forEach(([key, item]) => {
          if (item.id === origem) {
            descricao = item.descricao
          }
        })

        return descricao
      },
      getMultipleNewConContract () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Deseja buscar o(s) contrato(s) selecionado(s)?',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.get('/getMultipleNewConContract', { params: { contracts: this.paramsMultipleConstructor() } })
                  .then(() => {
                    alert(`Contrato(s) direcionado(s) para download!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao buscar contrato(s)! Tente novamente!')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      deleteMultipleContract () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Confirma o cancelamento do(s) contrato(s) selecionado(s)? Após cancelado(s) não será mais permitido assinatura de nenhum signatário.',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.delete('/deletarcontratos', { params: { contracts: this.paramsMultipleConstructor() } })
                  .then(() => {
                    alert(`Contrato(s) deletado(s) com sucesso!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao deletar contrato(s)! Tente novamente.')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      arquivarMultipleContract () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Confirma o arquivamento do(s) contrato(s) selecionado(s)? Essa ação não poderá ser desfeita!',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.post('/arquivarcontratos', { contracts: this.paramsMultipleConstructor() })
                  .then(() => {
                    alert(`Contrato(s) arquivado(s) com sucesso!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao arquivar contrato(s)! Tente novamente.')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      extendDeadlineMultiple () {
        this.showPopupProrrogar = true
      },
      resendSignatureRequestMultiple () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Deseja reenviar a(s) solicitação(ões) de assinatura(s) do(s) contrato(s) selecionado(s)?',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.post('/resendSignatureRequestMultiple', this.paramsMultipleConstructorObject())
                  .then(() => {
                    alert(`Solicitação(ões) reenviada(s) com sucesso!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao reenviar solicitação(ões) de assinatura(s)! Tente novamente.')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      cancelMultiple () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Confirma o cancelamento do(s) contrato(s) selecionado(s)? Após cancelados não será mais permitido assinatura de nenhum signatário.',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.delete(`/deletarcontratos`, { params: { contracts: this.paramsMultipleConstructor() } })
                  .then(() => {
                    alert(`Contrato(s) cancelado(s) com sucesso!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao cancelar contrato(s)! Tente novamente!')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      resendConfirmationEmailMultiple () {
        if (this.itemSelectedExits) {
          alert({
            title: 'Atenção',
            text: 'Deseja reenviar o(s) e-mail(s) de confirmação(ões) do(s) contrato(s) selecionado(s)?',
            img_topo: false,
            buttons: {
              'Sim': () => {
                this.$http.post('/resendConfirmationEmailMultiple', this.paramsMultipleConstructorObject())
                  .then(() => {
                    alert(`Contrato(s) reenviado(s) com sucesso!`)
                    this.filtrar()
                  })
                  .catch(() => {
                    error(null, 'Falha ao reenviar e-mail(s) de confirmação(ões)!')
                    this.filtrar()
                  })
              },
              'Não': () => true
            }
          })
        }
      },
      paramsMultipleConstructor () {
        return this.selectedItens.map(obj => obj.id_contrato)
      },
      paramsMultipleConstructorObject () {
        let novoArray = this.selectedItens.map(obj => ({
          signatarios: obj.signatarios,
          hash_contrato: obj.hash_contrato
        }))
        return { contracts: novoArray }
      },
      closeDialogExtendDeadline () {
        this.filtrar()
        this.showPopupProrrogar = false
      },
      openDialogImportCSV () {
        this.showPopupImportCSV = true
      },
      dialogImportCSV (itens) {
        this.limpar()
        if (itens.length > 0) {
          this.dataTableFiltroDefault.csvFilter = itens
          this.showCancellCsvFilter = true
          this.forceSelectAll = true
        }
        this.closeDialogImportCSV()
      },
      cancellCsvImport () {
        this.clearCsvImport()
        this.filtrar()
      },
      closeDialogImportCSV () {
        this.filtrar()
        this.showPopupImportCSV = false
      },
      clearCsvImport () {
        this.showCancellCsvFilter = false
        this.dataTableFiltroDefault.csvFilter = []
        this.forceSelectAll = false
      }
    }
  }
</script>

<style scoped>

.filtrar{
  text-transform:uppercase;
  border-radius: 18px;
}

.btn-filtar {
  font-size: 12px !important;
  border-radius: 12px !important;
}
  .home-btn {
    border-radius: 15px;
    font-size: 12px;
  }
  .home-btn p {
    height: 100%;
    margin: 0 0 0 5px;
  }
  .list-card {
    margin-left: 12px;
    border: solid 1px #cccccc;
    border-radius: 0 5px 5px 5px !important;
    z-index: 1 !important;
  }
  .home-tabs {
    list-style: none;
    width: 100%;
  }

  .signatario { /* É usado no JS */
    border-radius: 20px;
    font-size: 11px;
    float: left;
    background: #FEF2D7;
    color: #FAB512;
    padding: 1px 5px 1px 5px;
    margin-right: 5px;
  }
  .assinado { /* É usado no JS */
    background: #DEF1DE;
    color: #38A92D;
  }
  .signatarios-restantes {
    font-weight: bold;
    font-size: 12px;
  }
  .btn-menu {
    float: right;
  }
  .btn-menu span {
    vertical-align: center;
  }

  .menu-balloon {
    width: 250px !important;
  }

  .arrow-down-right {
    margin-left: 220px !important;
  }

  .table-home {
    border: 1px solid #CCCCCC;
    box-shadow: none;
    border-top-left-radius:0;
    margin-left: 12px;
    z-index: 0;
  }

</style>
