<template>
  <v-dialog v-on="$listeners" :value="value" :persistent="m_options.persistent" :width="m_options.width" content-class="app-content">
    <v-card style="border-radius: 16px; font-family: 'Product Sans', sans-serif;">
      <v-card-title class="text-h5">
        <button v-if="m_options.showCloseButton" class="close-button" @click="dismiss()"><v-icon>close</v-icon></button>
        <v-row justify="center" class="mt-4">
          <div v-if="m_options.img_topo">
            <v-col cols="12">
              <div v-if="m_options.error">
                <v-img src="../assets/img/error_dialog.png" />
              </div>
              <div v-else>
                <v-img src="../assets/img/ok_dialog.png" />
              </div>
            </v-col>
          </div>
          <v-col cols="12">
            <v-flex class="d-flex justify-center">
              <div v-if="m_options.img_topo">
                <div v-if="m_options.error"><span style="font-weight: bold;">Algo deu errado...</span></div>
                <div v-else style="font-weight: bold;">Tudo certo!</div>
              </div>
              <div v-else>
                <slot name="title"><span>{{ m_options.title }}</span></slot>
              </div>
            </v-flex>
          </v-col>
        </v-row>
      </v-card-title>
      <v-flex class="d-flex justify-center" pr-5 pl-5 id="content">
        <slot>
          <span class="pa-4 align-self-center">{{ m_options.text }}</span>
        </slot>
      </v-flex>
      <v-flex pl-4 pr-4 pb-7>
        <v-flex>
          <slot name="buttons">
            <v-flex :key="i" v-for="(btn, text, i) in m_options.buttons">
              <div v-if="btn.color || (i === 0)">
                <v-btn class="text button btn-default"
                       id="ButtonDialogFist"
                       :autofocus="btn.focus"
                       :class="btn.focus ? 'focus' : ''"
                       :color="btn.color || (i === 0 ? 'primary' : 'white')"
                       @click="handle(btn)">
                  {{ btn.text || text }}
                </v-btn>
              </div>
              <div v-else>
                <v-btn text class="text button btn-default"
                       id="ButtonDialog"
                       :autofocus="btn.focus"
                       :class="btn.focus ? 'focus' : ''"
                       :color="'primary'"
                       @click="handle(btn)">
                  {{ btn.text || text }}
                </v-btn>
              </div>
            </v-flex>
          </slot>
        </v-flex>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
  import { isObject } from '@/plugins/helpers'
  import { isString, isUndefined, omitBy, pick } from 'lodash'

  const defaultOptions = {
    title: 'Atenção!',
    text: '',
    buttons: {},
    persistent: true,
    showCloseButton: false,
    img_topo: true,
    error: false,
    width: 400
  }

  export default {
    name: 'Dialog',
    props: {
      title: String, // remove da tag
      value: Boolean,
      options: [Object, String],
      showCloseButton: Boolean,
      hideButtons: Boolean
    },
    computed: {
      m_options () {
        let options = this.options

        if (isString(options)) {
          options = {
            text: options, buttons: { 'OK': { focus: true } }
          }
        }

        const keys = Object.keys(defaultOptions)
        // O omitBy serve para remover as props sem valor default
        options = { ...defaultOptions, ...pick(this.$attrs, keys), ...omitBy(pick(this.$props, keys), isUndefined), ...options }

        if (this.hideButtons) {
          options.buttons = null
        }

        if (options.buttons) {
          Object.keys(options.buttons).forEach(key => {
            let btn = options.buttons[key]

            if (!isObject(btn)) {
              btn = options.buttons[key] = {
                handler: btn
              }
            }

            if (!btn.handler) {
              btn.handler = () => true
            }
          })
        }

        return options
      }
    },
    watch: {
      value (visible) {
        const actions = this.$refs.alert_actions
        if (visible && actions) {
          this.$nextTick(() => {
            const el = actions.querySelector('.focus')
            if (el) {
              el.focus()
            }
          })
        }
      }
    },
    methods: {
      dismiss () {
        this.$emit('input', false)
      },
      handle (h) {
        if (h && h.handler(this) !== false) {
          this.dismiss()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
    .headline {
        justify-content: flex-end;
        padding: 0 0 12px;
        margin: 0;
        border-bottom: solid 1px var(--lightgray);

        span {
            width: 100%;
            font-size: 1.2rem;
            text-transform: uppercase;
            color: var(--secondary);
            text-align: center;
        }
    }

    #content {
        text-align: center;
        max-height: 350px;
        min-height: 80px;
        overflow-y: auto;
        padding: 0 8px;

        span {
            padding: 8px 0;
            color: var(--dark);
            white-space: pre-wrap;
        }
    }

    .v-card {
      padding: 5px;
    }

    .alert_actions .v-btn {
        width: auto !important;
        margin-right: 10px;
        min-width: 120px !important;
    }

    .close-button {
        position: absolute;
        top: 8px;
        right: 12px;
        outline: none;
    }
</style>
