<template>
  <v-layout class="ma-0 pa-0 app-content fill-height col-sm-12">
    <v-row class="ma-0 pa-0 xs12 col-sm-12">
      <v-flex :class="pdfsrc ? 'hidden-sm-and-down col-md-5 col-sm-12 pb-200' : 'col-md-5 col-sm-12 pb-200'">
        <v-col class="d-flex row pa-0 ma-0">
          <img :src="`data:image/png;base64, ${logo}`" class="logo-usuario mt-8 ml-md-15 mr-auto pl-3" alt="logo">
          <v-progress-linear class="mt-5"
                             background-color="#E3E3E3"
                             :value="progresso"
                             color="primary" />
        </v-col>
        <v-col xs4 class="d-flex row pa-0 ma-0">
          <Signatario v-if="current_page === Pages.Signatario"
                      :administradora="administradora"
                      @getContrato="preencherContrato" />
          <SelecionarProposta v-if="current_page === Pages.SelecionarProposta"
                              :administradora="administradora"
                              :contratos="contratos"
                              @selecionarCotrato="selecionarCotrato" />
          <Proposta v-if="current_page === Pages.Proposta"
                    :administradora="administradora"
                    :contrato="contrato"
                    @nextPage="nextPage" />
          <Validacao v-if="current_page === Pages.Validacao"
                     :administradora="administradora"
                     :contrato="contrato"
                     @nextPage="nextPage" />
          <Contrato v-if="current_page === Pages.Contrato"
                    :administradora="administradora"
                    :contrato="contrato"
                    :assinar="assinatura"
                    @getPdf="preencherPdf" />
        </v-col>
      </v-flex>

      <v-footer absolute inset app id="footer" class="col-md-5 col-sm-12 pa-5 justify-center">
        <img src="../../assets/img/logo_assinatura.png">
      </v-footer>

      <v-flex :class="!pdfsrc ? 'hidden-sm-and-down col-md-7 pa-0' : ''" style="background-color: #f6f6f6">
        <div v-if="!pdfsrc" class="col-md-12 align-center pa-0" style="overflow: hidden; display: flex;">
          <strong class="cabecalho" style="margin-top: 30px;width: 100%;padding: 0px 10%;">Finalize as etapas de validação ao lado para visualizar o contrato</strong>
          <img class="col-md-7" src="../../assets/img/pdf-shell.png" alt="pdf" style="position: fixed; top: 120px; padding: 0 5%">
        </div>
        <v-flex id="pdf-scroll" v-show="pdfsrc">
          <div id="pdf-div">
            <v-flex class="d-flex pdf-top col-md-7">
              <v-flex class="d-flex align-self-center ml-4">
                <strong class="white--text">Contrato Consórcio.pdf</strong>
              </v-flex>
              <v-btn class="mr-8 justify-end align-self-center" icon @click="downloadPdf">
                <v-icon color="white">fa fa-download</v-icon>
              </v-btn>
            </v-flex>
            <div class="mt-16 mb-16">
              <Pdf class="offset-md-1 col-md-10" v-for="i in numPages" :key="i" :page="i" :src="pdfsrc" />
              <v-flex xs12 class="d-md-none mt-8 mr-10 ml-15">
                <p style="text-align: justify;font-size: 11px;">
                  Essa alternativa eletrônica é válida legalmente [vide MP 2200-02/01, Art. 10, §2º] e apta a produzir efeitos a partir da conclusão do documento por todos os destinatários.
                  Leia atentamente o contrato antes de assinar.
                </p>
                <v-btn x-large color="primary" class="button btn-default white--text" style="margin-bottom: 94px" @click="assinar">
                  Assinar contrato
                </v-btn>
              </v-flex>
            </div>
          </div>
        </v-flex>
      </v-flex>
    </v-row>
  </v-layout>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'
  import Rules from '../../plugins/validation'
  import Signatario from './pages/Signatario'
  import Proposta from './pages/Proposta'
  import Validacao from './pages/Validacao'
  import Contrato from './pages/Contrato'
  import Pdf from 'vue-pdf'
  import cores from '@/assets/values/colors'
  import SelecionarProposta from './pages/SelecionarProposta'

  export default {
    components: { SelecionarProposta, Contrato, Validacao, Proposta, Signatario, Pdf },
    data: () => ({
      name: 'Acesso',
      current_page: 1,
      contratos: {},
      contrato: {},
      administradora: {
        nome: '',
        cor_primaria: '',
        cor_secundaria: ''
      },
      logo: '',
      pdfsrc: '',
      numPages: undefined,
      url: '',
      assinatura: false
    }),
    computed: {
      rules: () => Rules,
      Pages () {
        return {
          Signatario: 1,
          SelecionarProposta: 2,
          Proposta: 3,
          Validacao: 4,
          Contrato: 5
        }
      },
      progresso () {
        return this.current_page * 20
      }
    },
    mounted () {
      document.getElementById('pdf-scroll').addEventListener('scroll', this.updateScroll)
      this.$http.get(`/dados/administradora/${this.$route.params.hash}`)
        .then((r) => {
          if (r.data.ok) {
            this.administradora = r.data.admin
            this.logo = r.data.logo
            this.$vuetify.theme.themes.light.primary = this.administradora.cor_primaria
            this.$vuetify.theme.themes.light.secondary = this.administradora.cor_secundaria
          } else {
            this.logo = r.data.logo
            this.$vuetify.theme.themes.light.primary = cores.primary
            this.$vuetify.theme.themes.light.secondary = cores.secondary
            throw new Error()
          }
        }).catch(() => { error(null, 'Não foi possível localizar o documento informado!') })
    },
    destroy () {
      document.getElementById('pdf-scroll').removeEventListener('scroll', this.updateScroll)
    },
    methods: {
      preencherPdf (value) {
        this.url = value
        this.pdfsrc = Pdf.createLoadingTask(value)
        this.pdfsrc.promise.then(pdf => {
          this.numPages = pdf.numPages
          // Assim que carregar o PDF já verifica se o scroll está até o final. Pode acontecer do PDF ser baixinho e
          // nem gerar rolagem na página, como e gatilho que disapara essa verificação é no scroll do PDF, ele nunca é verificado
          this.updateScroll()
        })
      },
      preencherContrato (value) {
        this.contratos = value
        if (this.contratos.length === 1) {
          this.contrato = this.contratos[0]
          this.current_page = this.Pages.SelecionarProposta
        }
        this.nextPage()
      },
      nextPage () {
        this.current_page++
      },
      selecionarCotrato (id_contrato) {
        this.contrato = this.contratos.find((contrato) => contrato.id_contrato === id_contrato) || {}
        this.nextPage()
      },
      downloadPdf () {
        const link = document.createElement('a')
        link.href = this.url
        link.setAttribute('download', this.contrato.id_documento + '_original.pdf')
        document.body.appendChild(link)
        link.click()
      },
      updateScroll () {
        let scrollHeight = document.getElementById('pdf-scroll')
        if (!this.assinatura) {
          this.assinatura = Math.ceil(scrollHeight.scrollTop) + scrollHeight.offsetHeight === scrollHeight.scrollHeight
        }
      },
      assinar () {
        this.$http.post('/contrato/registrar', { id_contrato: this.contrato.id_contrato, id_signatario: this.contrato.id_signatario })
          .then((r) => {
            if (r.data.ok) {
              alert({ text: r.data.msg, buttons: { 'Continuar': () => { this.$router.go(0) } } })
            } else { throw new Error() }
          })
          .catch(() => { error(null, 'Falha ao registrar assinatura! Tente novamente mais tarde.') })
      }
    }
  }
</script>

<style lang="scss" scoped>
#pdf-scroll{
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
}
.font-link {
  font-family: Product Sans,sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.08em;
  color: #949494;
}
#footer {
  background-color: #F7F7F7;
  padding-top: 100px;
}
.pdf {
  margin-top: 16px;
}
.pdf-top {
  position:fixed;
  height: 56px;
  background-color: #464646;
  right:0;
  top:0;

  z-index: 100;
}
.cabecalho {
  margin-right: 10px;
  font-size: 22px;
  color: var(--v-primary-base);
  text-align: center;
}
.pb-200{
  padding-bottom: 200px !important;
}

@media screen and (max-width: 959px) {
  #pdf-scroll{
    left: 0 !important;
  }
}
</style>
