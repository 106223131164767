<template>
  <v-layout wrap>
    <meta http-equiv="cache-control" content="no-cache" />
    <v-form class="col-sm-12" ref="form" @submit.prevent="nextPage">
      <v-flex xs12 class="d-flex justify-center">
        <label>Identificação</label>
      </v-flex>
      <v-flex xs12 class="d-flex mt-md-16 mr-md-10 ml-md-15 texto-large">
        <label>
          Você recebeu uma proposta para análise e assinatura!
        </label>
      </v-flex>
      <v-flex class="col-xs-12 mt-md-10 mr-md-10 ml-md-15">
        <label class="mt-12 label-input">CPF</label>
        <m-text-field v-model="cpf"
                      :rules="[ rules.required,rules.cpf ]"
                      placeholder="CPF"
                      color="primary"
                      validate-on-blur v-mask="'###.###.###-##'"
                      return-masked-value
                      dense
                      outlined
        />
      </v-flex>
      <v-flex class="col-xs-12 mr-md-10 ml-md-15">
        <label class="label-input">Data de Nascimento</label>
        <m-text-field v-model="model.data_nascimento"
                      :rules="[ rules.required,rules.date,rules.dataPassada ]"
                      placeholder="Data de Nascimento"
                      color="primary"
                      validate-on-blur v-mask="'##/##/####'"
                      return-masked-value
                      dense
                      outlined
        />
      </v-flex>
      <v-flex mr-10 ml-15 class="d-flex">
        <v-btn x-large color="primary" class="button btn-default white--text" type="submit">
          Acessar proposta
        </v-btn>
      </v-flex>
    </v-form>
  </v-layout>
</template>

<script>
  import Rules from '@/plugins/validation'
  import { unmask, error } from '@/plugins/helpers'

  export default {
    name: 'Signatario',
    data: () => ({
      name: 'Acesso',
      model: {
        data_nascimento: '',
        cpf: '',
        hash: '',
        latitude: '',
        longitude: ''
      },
      cpf: '',
      logo: '',
      coordenadas: {}
    }),
    computed: {
      rules: () => Rules
    },
    mounted () {
      this.pedirLocalizacao()
    },
    methods: {
      pedirLocalizacao () {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(this.getCoordenadas, this.erroCoordenadas)
        } else {
          error(null, 'O Navegador do cliente não suporta o uso de geolocalização.')
        }
      },
      getCoordenadas (pos) {
        this.coordenadas = pos.coords
      },
      erroCoordenadas () {
        error(null, 'Não foi possível localizar o seu dispositivo. \nPor favor, clique no ícone à esquerda da URL da página e mude o Local para "permitir", depois recarregue a página.')
      },
      nextPage () {
        const form = this.$refs.form
        this.model.hash = this.$route.params.hash
        this.model.cpf = unmask(this.cpf)
        this.model.latitude = this.coordenadas.latitude
        this.model.longitude = this.coordenadas.longitude
        if (form.validate()) {
          this.$http.post('/contrato/entrar', this.model)
            .then((r) => { if (r.data.ok) { this.$emit('getContrato', r.data.contratos) } else { error(null, r.data.msg_erro) } })
            .catch(() => { error(null, 'Falha ao validar acesso, tente novamente.') })
        }
      }
    }
  }
</script>

<style scoped>

</style>
