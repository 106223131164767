<template>
  <v-layout class="app-content">
    <v-main>
      <v-card
          color="grey lighten-4"
          flat
          height="100%"
          tile
      >
        <v-toolbar flat height="60" class="toolbar">
          <div class="logo-128">
            <img v-show="show_logo" :src="logo" class="img-logo" alt="logo-assinatura">
          </div>

          <v-toolbar-title class="pa-10">Gestão de assinaturas</v-toolbar-title>

          <v-spacer></v-spacer>

          <div class="pr-10" style="padding-bottom: 2px">
            <v-tabs centered height="60">
              <v-tab class="v-tab-lil" @click="acessar(menuRoute('home'))"><v-icon>icon-home</v-icon></v-tab>
              <v-menu origin="center center"
                      transition="slide-y-transition"
                      offset-y>
                <div class="arrow-up config"></div>
                <template v-slot:activator="{ on, attrs }">
                  <v-tab v-bind="attrs" v-on="on" class="v-tab-lil"><v-icon>icon-cog</v-icon></v-tab>
                </template>
                <v-list class="tooltip menu-balloon">
                  <div>
                    <v-list-item @click="acessar(menuRoute('aditivo_contratual'))">
                      <v-flex class="d-flex justify-center">Aditivos contratuais</v-flex>
                    </v-list-item>
                  </div>
                  <v-divider style="border-color:white !important"></v-divider>
                  <div>
                    <v-list-item @click="acessar(menuRoute('parametrizacao'))">
                      <v-flex class="d-flex justify-center">Configuração geral</v-flex>
                    </v-list-item>
                  </div>
                  <v-divider style="border-color:white !important"></v-divider>
                  <div>
                    <v-list-item @click="acessar(menuRoute('desativarsistema'))">
                      <v-flex class="d-flex justify-center">Desativar sistema</v-flex>
                    </v-list-item>
                  </div>
                </v-list>
              </v-menu>
              <v-menu origin="center center"
                      transition="slide-y-transition"
                      offset-y>
                <div class="arrow-up"></div>
                <template v-slot:activator="{ on, attrs }">
                  <v-tab v-bind="attrs" v-on="on" class="v-tab-lil"><v-icon>icon-user</v-icon></v-tab>
                </template>
                <v-list class="tooltip menu-balloon">
                  <div>
                    <v-list-item @click="acessar(menuRoute('usuario'))">
                      <v-flex class="d-flex justify-center">Gerenciar usuários</v-flex>
                    </v-list-item>
                  </div>
                  <v-divider style="border-color:white !important"></v-divider>
                  <div>
                    <v-list-item @click="logout"><v-flex class="d-flex justify-center">Sair</v-flex></v-list-item>
                  </div>
                </v-list>
              </v-menu>
            </v-tabs>
          </div>
        </v-toolbar>
        <router-view :key="$route.path" />
      </v-card>
      <v-card color="grey lighten-4" flat class="d-flex justify-center">
        <div class="mb-2">
          <img class="pl-16" src="../assets/img/logo_assinatura.png" alt="logo-assinatura">
        </div>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
  import { alert } from '@/plugins/helpers'
  import cores from '@/assets/values/colors'

  export default {
    name: 'Sistema',
    data: () => ({
      administradora: {},
      logo: '',
      show_logo: false
    }),
    mounted () {
      this.getLogo()
      this.loadCores()
    },
    methods: {
      menuRoute (name) {
        try {
          const route = this.$router.resolve({ name }).route
          const selected = this.$route.path.startsWith(route.path)
          return route.matched.length ? { text: route.meta.title, to: route.path, selected } : { text: '// fixme' }
        } catch (e) {
          return false
        }
      },
      acessar (item) {
        if (item.click) {
          item.click()
        } else {
          try {
            if (this.$route.path !== item.to) {
              this.$router.push(item.to)
            }
          } catch (e) {
            return false
          }
        }
      },
      getLogo () {
        const administradora = this.$session.get('administradora')
        this.$http.get(`/get/logo/administradora.${administradora.id_administradora}.png64`)
          .then((r) => {
            this.logo = `data:image/png;base64,${r.data}`
            this.show_logo = true
          })
      },
      loadCores () {
        const administradora = this.$session.get('administradora')

        if (!administradora.cor_primaria) {
          administradora.cor_primaria = '#979c99'
        }

        if (!administradora.cor_secundaria) {
          administradora.cor_secundaria = '#232323'
        }

        this.$vuetify.theme.themes.light.primary = administradora.cor_primaria
        this.$vuetify.theme.themes.light.secondary = administradora.cor_secundaria
      },
      logout () {
        alert({
          text: 'Tem certeza que deseja sair do sistema?',
          img_topo: false,
          buttons: {
            'Sair': () => {
              this.$vuetify.theme.themes.light.primary = cores.primary
              this.$vuetify.theme.themes.light.secondary = cores.secondary
              this.$router.replace({ name: 'logout' }).catch((e) => null)
            },
            'Cancelar': () => true
          }
        })
        return false
      }
    }
  }
</script>

<style scoped>
  .toolbar {
    border-bottom: 1px #dddddd solid;
  }

  .logo-128 {
    width: 128px;
    height: 40px;
    margin-top: 10px;
    margin-left: 80px;
    margin-bottom: 10px
  }

  .img-logo {
    width: 128px;
    height: 40px;
  }

  .config {
    margin-left: 75px !important;
  }
</style>
