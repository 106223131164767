<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex class="mt-4" style="display:inline ">
            <h1 class="ml-7">Relatório de assinaturas</h1>
          </v-flex>
        </v-layout>
      </v-flex>
      <p v-if="showList" class="mb-0 pt-8" style="margin-right: 400px"> {{ data_inicial | data_relatorio }} até {{ data_final | data_relatorio }}</p>
      <!--  BUTTONS  -->
      <v-row class="mt-5" justify="end">
        <v-dialog width="400" v-model="dialog" content-class="app-content">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ma-2 home-btn" dark color="primary" @click="showList = false">
              <v-icon>mdi-plus</v-icon>
              <p>NOVO RELATÓRIO</p>
            </v-btn>
          </template>
          <v-card style="border-radius: 16px">
            <v-form ref="form" @submit.prevent="setRange">
              <v-card-title class="text-h5">
                <v-flex class="d-flex">
                  <v-flex>Relatório de assinaturas</v-flex>
                  <v-menu bottom left>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="dialog = false">
                        <v-icon>fa fa-times</v-icon>
                      </v-btn>
                    </template>
                  </v-menu>
                </v-flex>
              </v-card-title>

              <v-card-text>
                <v-flex>
                  <v-flex>
                    <label class="label-input">Data inicial</label>
                    <DateField placeholder="Data inicial"
                               v-model="data_inicial"
                               max="today"
                               dense
                               outlined
                               validate-on-blur v-mask="'##/##/####'"
                               return-masked-value
                               :rules="[rules.required,validaDataInicial]">
                    </DateField>
                  </v-flex>
                  <v-flex mt-n2>
                    <label class="label-input">Data final</label>
                    <DateField placeholder="Data final"
                               v-model="data_final"
                               max="today"
                               dense
                               outlined
                               validate-on-blur v-mask="'##/##/####'"
                               return-masked-value
                               :rules="[rules.required,validaDataFinal]">
                    </DateField>
                  </v-flex>
                </v-flex>
                <v-flex>
                  <v-flex>
                    <v-btn x-large color="primary" class="button btn-default white--text" type="submit">
                      Gerar relatório
                    </v-btn>
                  </v-flex>
                  <v-flex mt-1>
                    <v-btn text x-large color="primary" class="btn-default" @click.stop="dialog = false">
                      Cancelar
                    </v-btn>
                  </v-flex>
                </v-flex>
              </v-card-text>
            </v-form>
          </v-card>
        </v-dialog>
      </v-row>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout v-if="showList" column mt-3 class="card">
        <DataTable
            ref="datatable" :url="url"
            class="pt-2 data-table-overlow"
            :headers="headers"
            :params="params"
            :show-total="true"
            :silent="true">
          <template v-slot:items="item">
            <td>
              {{ toDateBR(item.dia) }}
            </td>
            <td>
              {{ item.gerados }}
            </td>
            <td>
              {{ item.assinados }}
            </td>
          </template>
        </DataTable>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import Rules from '../plugins/validation'
  import { toDateBR } from '@/plugins/formatters'

  export default {
    name: 'RelatorioAssinaturas',
    components: { },
    filters: {
      data_relatorio: function (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    data: () => ({
      dialog: false,
      showList: false,
      administradora: {},
      mostraDialog: true,
      data_inicial: '',
      data_final: '',
      url: '/montarrelatorioassinatura',
      headers: [
        { text: 'Dia', value: 'dia' },
        { text: 'Contratos gerados', value: 'gerados' },
        { text: 'Contratos assinados', value: 'assinados' }
      ],
      pagination: {
        sortBy: [ 'dia' ], descending: [ true ], rowsPerPage: 50
      },
      params: {}
    }),
    computed: {
      rules: () => Rules
    },
    mounted () {
      this.administradora = this.$session.get('administradora')

      this.params = {
        pagination: this.pagination,
        id_administradora: this.administradora.id_administradora
      }
    },
    methods: {
      setRange () {
        if (this.$refs.form.validate()) {
          this.showList = true
          this.params = {
            ...this.params,
            data_inicial: this.data_inicial,
            data_final: this.data_final
          }
          this.dialog = false
        }
      },
      validaDataFinal () {
        const now = moment().format('YYYY-MM-DD')
        const data_inicial = moment(this.data_inicial)
        const data_final = moment(this.data_final)

        if (data_inicial.isValid() && data_final.isBefore(data_inicial)) {
          return 'Data Final não pode ser menor que a Data Inicial!'
        }
        if (data_final.isAfter(now)) {
          return 'Data Final não pode ser maior que a data atual'
        }

        return true
      },
      validaDataInicial () {
        const now = moment().format('YYYY-MM-DD')
        const data_inicial = moment(this.data_inicial)
        const data_final = moment(this.data_final)

        if (data_inicial.isValid() && data_final.isBefore(data_inicial)) {
          return 'Data Inicial não pode ser maior que a Data Final!'
        }

        if (data_inicial.isAfter(now)) {
          return 'Data Inicial não pode ser maior que a data atual'
        }

        return true
      },
      toDateBR (val) {
        return val.includes('-') ? toDateBR(val) : val
      }
    }
  }
</script>

<style scoped>
</style>
