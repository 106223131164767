import Vue from 'vue'
import * as formatters from './formatters'
import Rules from '../plugins/validation'
import { mask } from 'vue-the-mask'

import Loading from '../components/Loading'
import DataTable from '../components/DataTable'
import DateField from '../components/DateField'
import HomeTab from '../views/home/components/HomeTab'
import Dialog from '../components/Dialog'
import MForm from '../components/MForm'
import MTextField from '../components/MTextField'
import MoneyTextField from '../components/MoneyTextField'
import filters from '@/plugins/filters'

const components = {
  Dialog,
  MForm,
  MTextField,
  MoneyTextField,
  HomeTab,
  DataTable,
  DateField,
  Loading
}

Vue.mixin({
  components,
  filters: { ...formatters },
  directives: { mask },
  computed: {
    ws () { return this.$http.defaults.baseURL },
      $rules: () => Rules,
      $filters: () => filters
  }
})
