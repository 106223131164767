<template>
  <v-layout class="app-content">
    <v-main>
      <v-card
          color="grey lighten-4"
          flat
          height="100%"
          tile
      >
        <v-toolbar color="primary" flat height="60" class="toolbar">
          <div class="col-12 logo-center">
            <img src="../assets/img/logo_assinatura.png" alt="logo-assinatura">
          </div>

          <v-spacer></v-spacer>
        </v-toolbar>

        <m-form ref="form" @submit.prevent="validarContrato">
          <div class="col mt-10">
            <v-col class="content-center" cols="6">
              <label class="label-input" for="codigo_contrato">Código do contrato *</label>
              <m-text-field id="codigo_contrato"
                            v-model="model.hash_contrato"
                            placeholder="Código do contrato"
                            :rules="[rules.required]"
                            outlined
                            dense />
            </v-col>
            <v-col class="content-center" cols="4">
              <label class="label-input" for="cpf">CPF *</label>
              <m-text-field id="cpf"
                            v-model="model.cpf"
                            placeholder="CPF"
                            :rules="[rules.required]"
                            validate-on-blur
                            v-mask="'###.###.###-##'"
                            return-masked-value
                            outlined
                            dense />
            </v-col>
            <v-col class="content-center" cols="4">
              <label class="label-input" for="data_nascimento">Data de nascimento *</label>
              <m-text-field id="data_nascimento"
                            v-model="model.data_nascimento"
                            placeholder="Data de Nascimento"
                            :rules="[rules.required]"
                            color="primary"
                            validate-on-blur
                            v-mask="'##/##/####'"
                            return-masked-value
                            outlined
                            dense />
            </v-col>
            <v-col class="content-center" cols="3">
              <v-btn color="primary" class="button btn-default white--text" @click="validarContrato">Verificar</v-btn>
            </v-col>
          </div>
        </m-form>
      </v-card>
    </v-main>
  </v-layout>
</template>

<script>
  import { alert, error, unmask } from '../plugins/helpers'
  import Rules from '../plugins/validation'

  export default {
    name: 'ValidaContrato',
    data: () => ({
      model: {
        hash_contrato: '',
        cpf: '',
        data_nascimento: ''
      }
    }),
    computed: {
      rules: () => Rules
    },
    methods: {
      validarContrato () {
        if (this.$refs.form.validate()) {
          const params = {
            ...this.model,
            cpf: unmask(this.model.cpf)
          }
          this.$http.get('/contrato/valida-assinatura', { params: params })
            .then((resp) => {
              if (resp.data.ok) {
                alert(resp.data.msg)
              } else {
                throw new Error()
              }
            }).catch(() => {
              error(null, 'Não foi possível localizar o Contrato')
            })
        }
      }
    }
  }
</script>

<style scoped>
  .toolbar {
    border-bottom: 1px #dddddd solid;
  }

  .logo-center {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .content-center {
    display: block;
    margin: auto;
    min-width: 300px;
    max-width: 400px;
    padding: 0 10px 0 10px;
  }
</style>
