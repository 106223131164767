<template>
  <v-dialog v-model="dialog" width="500" content-class="app-content" persistent>
    <v-card style="border-radius: 16px; height: auto;">
      <v-form ref="reenvio">
        <v-container>
          <v-flex class="font-weight-bold black--text mb-2">
            Escolha a data de prorrogação:
          </v-flex>

          <DateField placeholder="Data prorrogação"
                     ref="dateField"
                     v-model="data_prorrogacao"
                     dense
                     validate-on-blur v-mask="'##/##/####'"
                     return-masked-value
                     outlined
                     :rules="[]"
          />
          <v-date-picker ref="datePicker" width="auto" :min="minDate" v-model="data_prorrogacao" />

          <v-card-actions>
            <v-flex>
              <v-btn color="primary" class="button btn-default white--text" @click="confirmProrogation">
                Confirmar
              </v-btn>
              <v-btn text color="primary" class="button btn-default white--text"
                     @click="close">
                Cancelar
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'

  export default {
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      contratos: []
    },
    data () {
      return {
        data_prorrogacao: '',
        showConfirmation: false,
        selectedDate: null,
        minDate: this.tomorrow()
      }
    },
    watch: {
      dialog (newValue) {
        if (!newValue) {
          this.data_prorrogacao = ''
          this.selectedDate = null
          this.$refs.datePicker.tableDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}`
        }
        this.$refs.dateField.reset()
      }
    },
    methods: {
      confirmProrogation () {
        if (!this.$refs.reenvio.validate()) {
          error(null, 'Data inválida.')
          return
        }

        const dataAtual = new Date()
        const dataComparar = new Date(this.data_prorrogacao)
        if (dataComparar <= dataAtual) {
          error(null, 'Data selecionada anterior ou igual a data atual! Tente novamente.')
          return
        }

        alert({
          title: 'Atenção',
          text: 'O(s) contrato(s) selecionado(s) serão prorrogado(s), deseja continuar',
          img_topo: false,
          buttons: {
            'Sim': () => {
              this.$http.post('/extendDeadlineMultiple', { contracts: this.contratos, date: this.data_prorrogacao })
                .then(() => {
                  alert(`Contrato(s) prorrogado(s)!`)
                  this.close()
                })
                .catch(() => {
                  error(null, 'Falha ao prorrogar contrato(s)! Tente novamente!')
                })
            },
            'Não': () => true
          }
        })
      },
      close () {
        this.dialog = false
        this.$emit('close-dialog')
      },
      tomorrow () {
        const tomorrow = new Date()
        tomorrow.setDate(tomorrow.getDate() + 1)
        return tomorrow.toISOString().slice(0, 10)
      }
    }
  }
</script>
