<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Novo Contrato</h1>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout column mt-3 class="card">
        <v-form ref="proposta">
          <v-flex mt-1 mb-4>
            <h3>Dados da Proposta</h3>
            <hr>
          </v-flex>
          <v-flex pt-1 pl-10 pr-10>
            <v-row>
              <v-col cols="6">
                <label class="label-input" for="Proposta">Proposta *</label>
                <m-text-field id="Proposta"
                              v-model="proposta_numero"
                              :rules="[rules.required]"
                              v-input-filter="[filters.numeric]"
                              placeholder="Proposta"
                              maxlength="15"
                              outlined
                              dense />
              </v-col>
              <v-col cols="6">
                <label class="label-input" for="valor_bem">Valor do bem *</label>
                <MoneyTextField id="valor_bem"
                                v-model="valor_bem"
                                :rules="[rules.required]"
                                :options="{ locale: 'pt-BR', suffix: '', precision: 2, prefix: '' }"
                                placeholder="Valor do bem"
                                maxlength="12"
                                outlined
                                dense />
              </v-col>
            </v-row>
            <v-layout class="pt-n7">
              <v-row>
                <v-col cols="6">
                  <label class="label-input" for="nome_bem">Nome do bem *</label>
                  <m-text-field id="nome_bem"
                                v-model="nome_bem"
                                :rules="[rules.required]"
                                v-uppercase
                                maxlength="50"
                                placeholder="Nome do bem"
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input" for="valor_parcela">Valor da parcela inicial *</label>
                  <MoneyTextField id="valor_parcela"
                                  v-model="valor_parcela"
                                  :rules="[rules.required]"
                                  :options="{ locale: 'pt-BR', suffix: '', precision: 2, prefix: '' }"
                                  placeholder="Valor da parcela inicial"
                                  maxlength="9"
                                  outlined
                                  dense />
                </v-col>
              </v-row>
            </v-layout>
            <v-layout>
              <v-row>
                <v-col cols="6">
                  <label class="label-input" for="codigo_grupo">Código do Grupo *</label>
                  <m-text-field id="codigo_grupo"
                                v-model="codigo_grupo"
                                :rules="[rules.required]"
                                v-input-filter="[filters.alphanumeric]"
                                v-uppercase
                                maxlength="50"
                                placeholder="Código do Grupo"
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input" for="codigo_plano">Código do Plano de venda *</label>
                  <m-text-field id="codigo_plano"
                                v-model="codigo_plano"
                                :rules="[rules.required]"
                                v-input-filter="[filters.alphanumeric]"
                                v-uppercase
                                maxlength="50"
                                placeholder="Código do Plano de venda"
                                outlined
                                dense />
                </v-col>
              </v-row>
            </v-layout>
            <v-layout>
              <v-row>
                <v-col cols="6"></v-col>
                <v-col cols="6">
                  <label class="label-input" for="nome_plano">Nome do Plano de venda *</label>
                  <m-text-field id="nome_plano"
                                v-model="nome_plano"
                                :rules="[rules.required]"
                                v-input-filter="[filters.alphanumeric]"
                                v-uppercase
                                maxlength="50"
                                placeholder="Nome do Plano de venda"
                                outlined
                                dense />
                </v-col>
              </v-row>
            </v-layout>
            <v-row>
              <v-col cols="6">
                <label class="label-input" for="qtd_parcelas">Quantidade de parcelas *</label>
                <m-text-field id="qtd_parcelas"
                              v-model="qtd_parcelas"
                              :rules="[ rules.required ]"
                              v-input-filter="[filters.numeric]"
                              placeholder="Quantidade de parcelas"
                              maxlength="5"
                              outlined
                              dense />
              </v-col>
              <v-col cols="6">
                <v-flex mt-7>
                  <v-radio-group row v-model="forma_contrato">
                    <v-radio label="Buscar contrato no Newcon" value="buscar"></v-radio>
                    <v-radio :disabled="!sistema_ativo" label=" Fazer upload do contrato" value="upload"></v-radio>
                  </v-radio-group>
                </v-flex>
              </v-col>
            </v-row>
          </v-flex>
          <v-flex mt-4 mb-4>
            <h3>{{ forma_contrato === 'buscar'? 'Informações do Newcon' : 'Enviar Contrato' }}</h3>
            <hr>
            <v-flex v-if="forma_contrato === 'buscar'" mt-5 pr-10 pl-10>
              <v-row>
                <v-col cols="6">
                  <label class="label-input" for="id_empresa">ID Empresa *</label>
                  <m-text-field id="id_empresa"
                                v-model="id_empresa"
                                :rules=[rules.required]
                                v-input-filter="[filters.numeric]"
                                placeholder="ID Empresa"
                                maxlength="5"
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input" for="id_tipo_documento">ID Tipo Documento *</label>
                  <m-text-field id="id_tipo_documento"
                                v-model="id_tipo_documento"
                                :rules=[rules.required]
                                v-input-filter="[filters.numeric]"
                                placeholder="ID Tipo Documento"
                                maxlength="15"
                                outlined
                                dense />
                </v-col>
              </v-row>
              <v-layout class="mb-2">
                <div style="padding-top: 1px;margin-right: 8px">
                  <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                </div>
                <div>
                  <div style="color: #797979;font-size: 10px">
                    <b>DICA PARA CONFIGURAÇÃO!</b><br>
                    Preencher os campos de <b>ID Empresa</b> e <b>ID Tipo Documento</b> conforme cadastrados no <b>Newcon</b>.
                  </div>
                </div>
              </v-layout>
            </v-flex>
            <v-flex v-if="forma_contrato === 'upload'" mt-5 pr-10 pl-10>
              <input type="file" id="file" ref="file" accept="application/pdf" @change="handleFileUpload()" />
              <v-layout class="mt-4 mb-2">
                <div style="padding-top: 1px;margin-right: 8px">
                  <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                </div>
                <div>
                  <div style="color: #797979;font-size: 10px">
                    <b>DICA PARA ENVIAR!</b><br>
                    O sistema só aceita arquivo do tipo <b>PDF</b> e com no máximo <b>5 MB</b>.
                  </div>
                </div>
              </v-layout>
            </v-flex>
          </v-flex>
        </v-form>
        <v-form ref="signatario">
          <v-flex mt-2 mb-4>
            <h3>Signatários</h3>
            <hr>
          </v-flex>
          <v-flex pt-1 pr-10 pl-10>
            <v-row>
              <v-col cols="12">
                <label class="label-input" for="signatario_nome">Nome pessoa *</label>
                <m-text-field id="signatario_nome"
                              v-model="signatario_nome"
                              :rules="[ rules.required ]"
                              v-input-filter="[filters.text]"
                              v-uppercase
                              placeholder="Nome pessoa"
                              maxlength="50"
                              outlined
                              dense />
              </v-col>
            </v-row>
            <v-layout class="pt-n7">
              <v-row>
                <v-col cols="6">
                  <label class="label-input" for="signatario_email">E-mail *</label>
                  <m-text-field id="signatario_email"
                                v-model="signatario_email"
                                :rules="[ rules.required,rules.email ]"
                                v-lowercase
                                placeholder="E-mail"
                                maxlength="50"
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input" for="signatario_cpf">CPF *</label>
                  <m-text-field id="signatario_cpf"
                                v-model="signatario_cpf"
                                :rules="[ rules.required,rules.cpf ]"
                                placeholder="CPF"
                                validate-on-blur
                                v-mask="'###.###.###-##'"
                                return-masked-value
                                outlined
                                dense />
                </v-col>
              </v-row>
            </v-layout>
            <v-layout class="pt-n7">
              <v-row>
                <v-col cols="6">
                  <label class="label-input" for="signatario_data_nascimento">Data de Nascimento *</label>
                  <m-text-field id="signatario_data_nascimento"
                                v-model="signatario_data_nascimento"
                                :rules="[ rules.required,rules.date,rules.dataPassada ]"
                                placeholder="Data de Nascimento"
                                color="primary"
                                validate-on-blur
                                v-mask="'##/##/####'"
                                return-masked-value
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input" for="signatario_celular">Celular {{ sms_token ? '*': '' }}</label>
                  <m-text-field id="signatario_celular"
                                v-model="signatario_celular"
                                placeholder="Celular"
                                validate-on-blur v-mask="'(##) #####-####'"
                                return-masked-value
                                :rules="[rules.required, rules.celular]"
                                outlined dense />
                </v-col>
              </v-row>
            </v-layout>
            <v-layout>
              <v-flex>
                <v-row class="ma-0" justify="end">
                  <v-btn color="primary" class="button btn-default white--text" @click="addSignatario">Adicionar signatário</v-btn>
                  <hr>
                </v-row>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-form>
        <v-flex pr-10 pl-10>
          <v-flex mt-4 mb-4 pa-1 :class="{ 'signatarios': !has_error_sig , 'error-signatarios': has_error_sig }">
            <v-flex v-if="!signatarios.length" class="d-flex justify-center" mt-2 mb-2>
              Nenhum signatário adicionado!
            </v-flex>
            <v-flex v-if="signatarios.length" class="mt-4">
              <v-virtual-scroll
                  :bench="benched"
                  :items="signatarios"
                  height="110"
                  item-height="90">
                <template v-slot:default="{ item }">
                  <v-list-item :key="item.nome">
                    <v-row>
                      <v-col cols="4" sm="5">
                        <span>{{ item.nome }}</span>
                        <br>
                        <span class="text-cinza">{{ item.email }}</span>
                        <br>
                        <span class="text-cinza">{{ item.celular }}</span>
                      </v-col>
                      <v-col cols="4" sm="3">
                        <v-flex class="d-flex justify-end">
                          <span class="text-cinza">{{ item.cpf }}</span>
                        </v-flex>
                        <v-flex class="d-flex justify-end">
                          <span class="text-cinza">{{ item.dt_nascimento | data_nascimento }}</span>
                        </v-flex>
                      </v-col>
                      <v-col cols="4" sm="4">
                        <v-flex class="d-flex justify-end">
                          <v-btn text @click="removerSignatario(item)">
                            <v-icon left>
                              close
                            </v-icon>
                            <span style="font-size: 12px">remover</span>
                          </v-btn>
                        </v-flex>
                      </v-col>
                    </v-row>
                  </v-list-item>
                  <v-divider class="mt-4"></v-divider>
                </template>
              </v-virtual-scroll>
            </v-flex>
          </v-flex>
        </v-flex>
        <v-flex pr-10 pl-10>
          <v-row class="ma-0" justify="end">
            <v-btn text class="button btn-default" :to="{ name: 'home'}">Cancelar</v-btn>
            <v-btn color="primary" class="button btn-default white--text" @click="inserirContrato">Inserir novo contrato</v-btn>
          </v-row>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
  import Rules from '@/plugins/validation'
  import Filters from '@/plugins/filters'
  import moment from 'moment'
  import { alert, error } from '@/plugins/helpers'

  export default {
    name: 'CadastrarContrato',
    filters: {
      data_nascimento: function (date) {
        return moment(date).format('DD/MM/YYYY')
      }
    },
    data: () => ({
      forma_contrato: 'buscar',
      proposta: {},
      id_empresa: '',
      id_tipo_documento: '',
      proposta_numero: '',
      nome_bem: '',
      valor_bem: '',
      codigo_grupo: '',
      codigo_plano: '',
      nome_plano: '',
      qtd_parcelas: '',
      valor_parcela: '',
      signatarios: [],
      benched: 0,
      signatario_nome: '',
      signatario_email: '',
      signatario_cpf: '',
      signatario_data_nascimento: '',
      signatario_celular: '',
      administradora: 0,
      has_error_sig: false,
      sms_token: false,
      sistema_ativo: true,
      file: ''
    }),
    computed: {
      rules: () => Rules,
      filters: () => Filters
    },
    mounted () {
      this.administradora = this.$session.get('administradora')
      this.$http.get(`/admin/parametrizacao/${this.administradora.id_administradora}`)
        .then((r) => {
          this.sms_token = r.data.administradora.tipo_validacao !== 'somente_email'
          this.sistema_ativo = r.data.administradora.sistema_ativo
        })
    },
    methods: {
      handleFileUpload () {
        this.file = this.$refs.file.files[0]
      },
      addSignatario () {
        if (this.$refs.signatario.validate()) {
          this.has_error_sig = false
          let signatario = {
            nome: this.signatario_nome,
            email: this.signatario_email,
            cpf: this.signatario_cpf,
            dt_nascimento: moment(this.signatario_data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            celular: this.signatario_celular
          }
          this.signatarios.push(signatario)
          this.$refs.signatario.reset()
          this.signatario_nome = ''
        }
      },
      inserirContrato () {
        if (!this.$refs.proposta.validate()) { return }
        if (this.signatarios.length <= 0) {
          this.has_error_sig = true
          return
        }
        this.proposta = {
          id_empresa: this.id_empresa,
          id_tipo_documento: this.id_tipo_documento,
          id_documento: this.proposta_numero,
          vl_bem: this.valor_bem,
          nm_bem: this.nome_bem,
          vl_parcela_incial: this.valor_parcela,
          cd_grupo: this.codigo_grupo,
          cd_plano: this.codigo_plano,
          nm_plano: this.nome_plano,
          no_parcelas: this.qtd_parcelas
        }
        this.verificarNumeroProposta()
      },
      inserirContatoArquivo () {
        let params = {}
        params = this.proposta
        params.signatarios = this.signatarios
        let hash = ''
        this.$http.post(`/admin/cadastro/contrato/dados/${this.administradora.id_administradora}`, params)
          .then((r) => {
            hash = r.data.hash
            let formData = new FormData()
            formData.append('file', this.file)
            formData.append('id_documento', this.proposta_numero)
            formData.append('hash', hash)

            return this.$http.post(`/admin/cadastro/contrato/arquivo/${this.administradora.id_administradora}`,
                                   formData,
                                   { headers: { 'Content-Type': 'multipart/form-data' } })
          })
          .then((r) => {
            if (r.data.ok) {
              alert('Contrato cadastrado com sucesso!')
              this.$router.push({ name: 'home' })
            } else {
              error(null, r.data.msg_erro)
            }
          })
          .catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      inserirContratoBusca () {
        let params = {}
        params = this.proposta
        params.signatarios = this.signatarios
        this.$http.post(`/admin/cadastro/contrato/manual/${this.administradora.id_administradora}`, params)
          .then(() => {
            alert('Contrato cadastrado com sucesso!')
            this.$router.push({ name: 'home' })
          })
          .catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      },
      removerSignatario (signatario) {
        alert({
          text: `Deseja remover o signatário ${signatario.nome} do contrato?`,
          img_topo: false,
          showCloseButton: true,
          buttons: {
            'Sim': () => {
              const index = this.signatarios.indexOf(signatario)
              if (index > -1) {
                this.signatarios.splice(index, 1)
              }
            },
            'Não': () => true }
        })
      },
      verificarNumeroProposta () {
        let params = {}
        params.proposta_numero = this.proposta_numero
        this.$http.post(`/admin/cadastro/contrato/validar/numeroproposta/${this.administradora.id_administradora}`, params)
          .then((r) => {
            if (!r.data.ok) {
              alert({
                text: `Esse número de contrato já existe, deseja continuar com o mesmo número?`,
                img_topo: false,
                showCloseButton: true,
                buttons: {
                  'Sim': () => {
                    if (this.forma_contrato === 'buscar') {
                      this.inserirContratoBusca()
                    } else {
                      this.inserirContatoArquivo()
                    }
                  },
                  'Não': () => true
                }
              })
            } else {
              if (this.forma_contrato === 'buscar') {
                this.inserirContratoBusca()
              } else {
                this.inserirContatoArquivo()
              }
            }
          })
          .catch(() => { error(null, 'Ocorreu algum problema durante a última ação. Por favor, tente novamente. Se o erro persistir entre em contato conosco.') })
      }
    }
  }
</script>

<style scoped>
  .v-text-field__slot{
    text-transform: none;
  }
  .v-btn.btn-default:not(.shrink) {
    width: 240px !important;
  }
  .error-signatarios {
    border: 2px solid red;
    border-radius: 8px;
    color: red;
  }
  .signatarios {
    border: 1px solid #9e9e9e;
    border-radius: 8px
  }
  .text-cinza {
    color: #838383;
  }
</style>
