<template>
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Parametrização</h1>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout column mt-3 class="card">
        <v-flex class="marginlr-40">
          Configurações
          <v-divider class="mt-4"></v-divider>
        </v-flex>
        <v-form ref="config" @submit.prevent="salvarParametrizacaoAdmin">
          <v-layout class="corpo-div paddingrl-47-60">
            <v-row>
              <v-col cols="7">
                <label class="label-input">Tipo de validação *</label>
                <v-flex class="d-flex">
                  <div>
                    <v-radio-group v-model="configuracao_adm.tipo_validacao">
                      <v-radio :label="`Somente por E-mail`" value="somente_email"></v-radio>
                      <v-radio :label="`Somente por SMS`" value="somente_sms"></v-radio>
                      <v-radio :label="`E-mail e SMS`" value="email_e_sms" />
                      <v-radio :label="`E-mail ou SMS`" value="email_ou_sms" />
                    </v-radio-group>
                  </div>
                </v-flex>
                <label class="label-input">Em quantos dias os contratos devem expirar? *</label>
                <m-text-field v-model="configuracao_adm.qtd_dia_expiracao"
                              :rules="[rules.required]"
                              v-input-filter="[filters.numeric]"
                              class="mt-1 wi-200"
                              outlined
                              dense
                              maxlength="3"
                              placeholder="Quantidade de dias" />
              </v-col>
              <v-col cols="5">
                <span class="label-input">Tempo de expiração do token *</span>
                <v-slider class="square-thumb" v-model="configuracao_adm.tmp_expiracao_token" step="1" max="60" min="1" tick-size="0" thumb-label="always">
                  <template slot="thumb-label">
                    <span>{{ configuracao_adm.tmp_expiracao_token === 1 ? configuracao_adm.tmp_expiracao_token +' minuto': configuracao_adm.tmp_expiracao_token +' minutos' }}</span>
                  </template>
                </v-slider>
                <span class="label-input">Em quantos dias antes da expiração do contrato o cliente deve ser alertado? *</span>
                <m-text-field v-model="configuracao_adm.qtd_dia_lembrete"
                              :rules="[rules.required]"
                              v-input-filter="[filters.numeric]"
                              class="mt-1 wi-200"
                              outlined
                              dense
                              maxlength="3"
                              placeholder="Quantidade de dias" />
                <v-layout class="mb-2" style="margin-right: 40px">
                  <div style="margin-right: 8px">
                    <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                  </div>
                  <div>
                    <div style="color: #797979;font-size: 10px">
                      <strong>DICA PARA CONFIGURAÇÂO</strong><br>
                      Os clientes serão sempre alertados nos {{ configuracao_adm.qtd_dia_lembrete || 0 }} dias anteriores à data da expiração do contrato.
                    </div>
                  </div>
                </v-layout>
              </v-col>
              <v-layout class="mb-2" style="margin-right: 40px">
                <div style="margin-right: 8px">
                  <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                </div>
                <div>
                  <div style="color: #797979;font-size: 10px">
                    <strong>DICA PARA CONFIGURAÇÂO</strong><br>
                    A configuração de dias nos campos acima são referentes à <strong>data de criação do contrato</strong>. Será <strong>somado</strong> o valor de data configurado para envio de lembrete e expiração junto à data de criação do contrato.
                  </div>
                </div>
              </v-layout>
              <v-row class="mt-6" justify="end">
                <v-btn text class="button btn-default" @click="cancelarParametrizacaoAdmin">Cancelar alterações</v-btn>
                <v-btn color="primary" class="button btn-default white--text" type="submit">Salvar configurações</v-btn>
              </v-row>
            </v-row>
          </v-layout>
        </v-form>
        <v-flex class="marginlr-40 mt-13">
          Cópia dos contratos assinados
          <v-divider class="mt-4"></v-divider>
          <v-form ref="emailcopiacontratoform" @submit.prevent="salvarParametrizacaoEmailCopiaContratos">
            <v-flex class="corpo-div">
              <span class="label-input">Emails para envio de confirmação *</span>
              <EmailComboBox :items.sync="itemsConfirmacao" v-model="configuracao_email_campos_confirmacao.email_copia_confirmacao" :rules="[rules.required]" />
              <v-row class="mt-6" justify="end">
                <v-btn text class="button btn-default" @click="cancelarParametrizacaoEmailCamposConfirmacao">Cancelar alterações</v-btn>
                <v-btn color="primary" class="button btn-default white--text" type="submit">Salvar configurações</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-flex>
        <v-flex class="marginlr-40 mt-13">
          Configurações do disparo de e-mail
          <v-divider class="mt-4"></v-divider>
          <v-form ref="emailform" @submit.prevent="salvarParametrizacaoEmailCampos">
            <v-flex class="corpo-div">
              <span class="label-input">Responder para *</span>
              <EmailComboBox :items.sync="items" v-model="configuracao_email_campos.email_resposta" :rules="[rules.required]" />
              <label class="label-input">Assunto do e-mail de envio do contrato *</label>
              <m-text-field v-model="configuracao_email_campos.email_assunto"
                            :rules="[rules.required]"
                            maxlength="80"
                            placeholder="Assunto do e-mail de envio do contrato"
                            outlined
                            dense />
              <label class="label-input">Texto do e-mail de envio do contrato *</label>
              <v-textarea v-model="configuracao_email_campos.email_corpo"
                          :rules="[rules.required]"
                          placeholder="Texto do e-mail de envio do contrato"
                          maxlength="500"
                          counter="500"
                          no-resize
                          outlined></v-textarea>
              <label class="label-input">Assunto do e-mail de confirmação de assinatura *</label>
              <m-text-field v-model="configuracao_email_campos.email_assunto_confirmar"
                            :rules="[rules.required]"
                            placeholder="Assunto do e-mail de confirmação de assinatura"
                            maxlength="80"
                            outlined
                            dense />
              <v-layout class="mb-2">
                <div style="padding-top: 13px;margin-right: 8px">
                  <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                </div>
                <div>
                  <div style="color: #797979;font-size: 10px">
                    <strong>DICA DE VARIÁVEIS</strong><br>
                    Você pode usar variáveis que serão substituídas por dados do usuário que irá receber a mensagem.<br>
                    <strong>$nome_cliente</strong> = Nome do usuário <strong>$cpf</strong> = CPF do usuário <strong>$proposta</strong> = Número da proposta
                  </div>
                </div>
              </v-layout>
              <v-row class="mt-6" justify="end">
                <v-btn text class="button btn-default" @click="cancelarParametrizacaoEmailCampos">Cancelar alterações</v-btn>
                <v-btn color="primary" class="button btn-default white--text" type="submit">Salvar configurações</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-flex>
        <v-flex class="marginlr-40 mt-13">
          Configurações do disparo de SMS
          <v-divider class="mt-4"></v-divider>
          <v-form ref="smsform" @submit.prevent="salvarParametrizacaoSMSCampos">
            <v-flex class="corpo-div">
              <label class="label-input">Texto do SMS de envio do contrato *</label>
              <m-text-field v-model="configuracao_sms_campos.sms_corpo"
                            :rules="[rules.required]"
                            v-input-filter="[filters.sms]"
                            placeholder="Texto do SMS de envio do contrato"
                            :counter="max_length_sms_corpo"
                            :maxlength="max_length_sms_corpo"
                            outlined
                            dense />
              <label class="label-input">Texto do SMS de confirmação *</label>
              <m-text-field v-model="configuracao_sms_campos.sms_corpo_final"
                            :rules="[rules.required]"
                            v-input-filter="[filters.sms]"
                            placeholder="Texto do SMS de confirmação"
                            :maxlength="max_length_sms_corpo_final"
                            :counter="max_length_sms_corpo_final"
                            outlined
                            dense />
              <v-layout class="mb-2">
                <div style="padding-top: 13px;margin-right: 8px">
                  <v-icon size="19" color="primary">icon-lightbulb-on</v-icon>
                </div>
                <div>
                  <div style="color: #797979;font-size: 10px">
                    <strong>DICA DE VARIÁVEIS</strong><br>
                    Você pode usar variáveis que serão substituídas por dados do usuário que irá receber a mensagem.<br>
                    <strong>$nome_cliente</strong> = Nome do usuário <strong>$cpf</strong> = CPF do usuário <strong>$proposta</strong> = Número da proposta
                  </div>
                </div>
              </v-layout>
              <v-row class="mt-6" justify="end">
                <v-btn text class="button btn-default" @click="cancelarParametrizacaoSMSCampos">Cancelar alterações</v-btn>
                <v-btn color="primary" class="button btn-default white--text" type="submit">Salvar configurações</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-flex>
        <v-flex v-show="false" class="marginlr-40 mt-2">
          Configurar serviço de e-mail
          <v-divider class="mt-4"></v-divider>
          <v-form ref="emailconfig" @submit.prevent="salvarParametrizacaoConfigEmail">
            <v-flex class="corpo-div">
              <label class="label-input">Nome de descrição *</label>
              <m-text-field v-model="configuracao_email.email_nome"
                            :rules="[rules.required]"
                            placeholder="Nome de descrição"
                            v-uppercase
                            outlined
                            dense />
              <v-row>
                <v-col cols="6">
                  <label class="label-input">Servidor *</label>
                  <m-text-field v-model="configuracao_email.email_host"
                                :rules="[rules.required]"
                                placeholder="Servidor"
                                v-lowercase
                                outlined
                                dense />
                </v-col>
                <v-col cols="6">
                  <label class="label-input">Porta *</label>
                  <m-text-field v-model="configuracao_email.email_porta"
                                :rules="[rules.required]"
                                v-input-filter="[filters.numeric]"
                                placeholder="Porta"
                                outlined
                                dense />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <label class="label-input">Protocolo de envio *</label>
                  <v-radio-group row v-model="configuracao_email.email_driver">
                    <v-radio label="SMTP" value="smtp"></v-radio>
                    <v-radio label="IMAP" value="imap"></v-radio>
                    <v-radio label="POP3" value="pop3"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="6">
                  <label class="label-input">Segurança de conexão *</label>
                  <v-radio-group row v-model="configuracao_email.email_encryption">
                    <v-radio label="STARTTLS" value="starttls"></v-radio>
                    <v-radio label="SSL/TLS" value="tls"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <label class="label-input">Endereço do e-mail *</label>
              <m-text-field v-model="configuracao_email.email_user"
                            :rules="[rules.required, rules.email]"
                            placeholder="Endereço do e-mail"
                            v-lowercase
                            outlined
                            dense
                            autocomplete="new-password"
              />
              <v-row>
                <v-col cols="6">
                  <label class="label-input">Senha do e-mail *</label>
                  <m-text-field v-model="email_senha"
                                :type="pass_visible ? 'text' : 'password'"
                                placeholder="Senha do e-mail"
                                outlined
                                dense
                                autocomplete="new-password">
                    <v-icon slot="append" @click="pass_visible = !pass_visible"
                            :color="pass_visible ? 'primary' : undefined">
                      {{ pass_visible ? 'far fa-eye' : 'far fa-eye-slash' }}
                    </v-icon>
                  </m-text-field>
                </v-col>
                <v-col cols="6">
                  <label class="label-input">Confimar Senha do e-mail *</label>
                  <m-text-field v-model="confirmar_senha"
                                :type="pass_visible ? 'text' : 'password'"
                                placeholder="Confimar Senha do e-mail"
                                outlined
                                :error-messages="validar_senha(email_senha, confirmar_senha)"
                                dense
                                validate-on-blur
                                autocomplete="new-password">
                    <v-icon slot="append" @click="pass_visible = !pass_visible"
                            :color="pass_visible ? 'primary' : undefined">
                      {{ pass_visible ? 'far fa-eye' : 'far fa-eye-slash' }}
                    </v-icon>
                  </m-text-field>
                </v-col>
              </v-row>
              <div>
                <v-btn color="primary" style="font-size: 9px" class="button btn-default white--text" @click="validarModalEmail">Validação de configuração do e-mail</v-btn>
              </div>
              <v-row class="mt-1 mb-10" justify="end">
                <v-btn text class="button btn-default" @click="cancelarParametrizacaoConfigEmail">Cancelar alterações</v-btn>
                <v-btn color="primary" class="button btn-default white--text" type="submit">Salvar configuração e-mail</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-dialog v-model="dialog" width="500" :scrollable="true" content-class="app-content" persistent>
      <v-card style="border-radius: 16px">
        <v-card-title class="text-h5">
          <v-flex class="d-flex">
            <v-flex>Validação serviço de e-mail</v-flex>
            <v-menu bottom left>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon @click="dialog = false">
                  <v-icon>fa fa-times</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-flex>
        </v-card-title>
        <v-card-text>
          <v-form ref="emailteste" @submit.prevent="validarServicoEmail">
            <v-flex mt-4>
              <m-text-field v-model="email_teste"
                            :rules="[rules.required, rules.email]"
                            v-lowercase
                            label="E-mail para envio da validação"
                            dense
                            outlined
                            validate-on-blur />
            </v-flex>
            <v-flex>
              <v-row class="ma-0" justify="end">
                <v-btn color="primary" class="button btn-default white--text" type="submit">Validar serviço</v-btn>
              </v-row>
            </v-flex>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'
  import Rules from '@/plugins/validation'
  import EmailComboBox from '@/views/parametrizacao/components/EmailComboBox'
  import Filters from '../../plugins/filters'
  import { Mensagens } from '@/mensagens'

  export default {
    name: 'Parametrizacao',
    components: { EmailComboBox },
    data: () => ({
      administradora: {},
      configuracao_adm: {},
      copia_configuracao_adm: {},
      configuracao_email_campos: { email_resposta: [] },
      configuracao_email_campos_confirmacao: { email_copia_confirmacao: [] },
      copia_configuracao_email_campos: {},
      copia_configuracao_email_campos_confirmacao: {},
      configuracao_email: {},
      copia_configuracao_email: {},
      email_senha: '',
      dialog: false,
      pass_visible: false,
      items: [],
      itemsConfirmacao: [],
      email_teste: '',
      confirmar_senha: '',
      copia_configuracao_sms_campos: {},
      configuracao_sms_campos: { sms_corpo: '', sms_corpo_final: '' }
    }),
    computed: {
      rules: () => Rules,
      filters: () => Filters,
      max_length_sms_corpo () {
        return this.smsCorpoMaxLength()
      },
      max_length_sms_corpo_final () {
        return this.smsCorpoFinalMaxLength()
      }
    },
    watch: {
      email_resposta (val) {
        this.configuracao_email_campos.email_resposta = val
      },
      email_copia_confirmacao (val) {
        this.configuracao_email_campos_confirmacao = val
      }
    },
    mounted () {
      this.configuracao_adm.tipo_validacao = 'somente_email'
      this.administradora = this.$session.get('administradora')
      this.$http.get(`/admin/parametrizacao/${this.administradora.id_administradora}`)
        .then((r) => {
          this.configuracao_adm = r.data.administradora
          this.configuracao_email_campos = r.data.configuracao_email_campos
          this.configuracao_email = r.data.configuracao_email
          this.configuracao_sms_campos = r.data.configuracao_sms_campos
          this.copia_configuracao_adm = { ...this.configuracao_adm }
          this.copia_configuracao_email_campos = { ...this.configuracao_email_campos }
          this.copia_configuracao_email = { ...this.configuracao_email }
          this.copia_configuracao_sms_campos = { ...this.copia_configuracao_sms_campos }

          this.configuracao_email_campos.email_resposta.forEach((email) => {
            this.items.push(email)
          })
        })
      this.$http.get(`/admin/parametrizacao/email/copia/contrato/assinado/${this.administradora.id_administradora}`)
        .then((r) => {
          this.configuracao_email_campos_confirmacao = r.data.configuracao_email_campos_confirmacao
          this.copia_configuracao_email_campos_confirmacao = { ...this.configuracao_email_campos_confirmacao }

          for (var i = 0; i < this.configuracao_email_campos_confirmacao.length; i++) {
            this.itemsConfirmacao.push(this.configuracao_email_campos_confirmacao[i])
          }
        })
    },
    methods: {
      smsCorpoMaxLength () {
        return this.myMaxLength(this.configuracao_sms_campos.sms_corpo)
      },
      smsCorpoFinalMaxLength () {
        return this.myMaxLength(this.configuracao_sms_campos.sms_corpo_final)
      },
      myMaxLength (val) {
        let max = 140
        if (val.includes('$nome_cliente')) {
          max -= (2 * (val.split('$nome_cliente').length - 1))
        }
        if (val.includes('$cpf')) {
          max -= (10 * (val.split('$cpf').length - 1))
        }
        if (val.includes('$proposta')) {
          max -= (6 * (val.split('$proposta').length - 1))
        }
        return max
      },
      validar_senha (senha, confirmar_senha) {
        return senha === confirmar_senha ? '' : 'As senhas não correspondem!'
      },
      cancelarParametrizacaoAdmin () {
        this.configuracao_adm = { ...this.copia_configuracao_adm }
      },
      cancelarParametrizacaoSMSCampos () {
        this.configuracao_sms_campos = { ...this.copia_configuracao_sms_campos }
      },
      cancelarParametrizacaoEmailCampos () {
        this.configuracao_email_campos = { ...this.copia_configuracao_email_campos }
        this.items = []
        this.configuracao_email_campos.email_resposta.forEach((email) => {
          this.items.push(email)
        })
      },
      cancelarParametrizacaoEmailCamposConfirmacao () {
        if (this.itemsConfirmacao.length > 0) {
          this.configuracao_email_campos_confirmacao = { ...this.copia_configuracao_email_campos_confirmacao }
          this.itemsConfirmacao = []
        } else {
          error(null, Mensagens.falhaCancelarAlteracoes)
        }
      },
      cancelarParametrizacaoConfigEmail () {
        this.configuracao_email = { ...this.copia_configuracao_email }
        this.confirmar_senha = ''
        this.email_senha = ''
        this.configuracao_email.email_senha = this.email_senha
      },
      salvarParametrizacaoAdmin () {
        if (this.$refs.config.validate()) {
          this.$http.post(`/admin/parametrizacao/configuracoes/salvar/${this.administradora.id_administradora}`, this.configuracao_adm)
            .then(() => {
              this.copia_configuracao_adm = { ...this.configuracao_adm }
              alert(Mensagens.configuracaoSucesso)
            })
            .catch(() => {
              error(null, Mensagens.falhaAlteracoes)
            })
        }
      },
      salvarParametrizacaoSMSCampos () {
        if (this.$refs.smsform.validate()) {
          this.$http.post(`/admin/parametrizacao/sms/salvar/${this.administradora.id_administradora}`, this.configuracao_sms_campos)
            .then(() => {
              this.copia_configuracao_sms_campos = { ...this.configuracao_sms_campos }
              alert(Mensagens.configuracaoSucesso)
            })
            .catch(() => {
              error(null, Mensagens.falhaAlteracoes)
            })
        }
      },
      salvarParametrizacaoEmailCampos () {
        if (this.$refs.emailform.validate()) {
          this.$http.post(`/admin/parametrizacao/email/salvar/${this.administradora.id_administradora}`, this.configuracao_email_campos)
            .then(() => {
              this.copia_configuracao_email_campos = { ...this.configuracao_email_campos }
              this.items = []
              this.configuracao_email_campos.email_resposta.forEach((email) => {
                this.items.push(email)
              })
              alert(Mensagens.configuracaoSucesso)
            })
            .catch(() => {
              error(null, Mensagens.falhaAlteracoes)
            })
        }
      },
      salvarParametrizacaoEmailCopiaContratos () {
        if (this.configuracao_email_campos_confirmacao.email_copia_confirmacao.length > 0) {
          if (this.$refs.emailcopiacontratoform.validate()) {
            this.$http.post(`/admin/parametrizacao/email/copia/assinatura/salvar/${this.administradora.id_administradora}`, this.configuracao_email_campos_confirmacao)
              .then(() => {
                this.copia_configuracao_email_campos_confirmacao = { ...this.configuracao_email_campos_confirmacao }
                this.itemsConfirmacao = []
                this.configuracao_email_campos_confirmacao.email_copia_confirmacao.forEach((email) => {
                  this.itemsConfirmacao.push(email)
                })
                alert(Mensagens.configuracaoSucesso)
              })
              .catch(() => {
                error(null, Mensagens.falhaAlteracoes)
              })
          }
        } else {
          error(null, Mensagens.validacaoCampoEmailVazio)
        }
      },
      salvarParametrizacaoConfigEmail () {
        this.configuracao_email.email_senha = this.email_senha
        if (this.$refs.emailconfig.validate()) {
          if (this.email_senha === this.confirmar_senha) {
            this.$http.post(`/admin/parametrizacao/email/configuracoes/salvar/${this.administradora.id_administradora}`, this.configuracao_email)
              .then(() => {
                this.copia_configuracao_email = { ...this.configuracao_email }
                alert(Mensagens.configuracaoSucesso)
              })
              .catch(() => {
                error(null, Mensagens.falhaAlteracoes)
              })
          }
        }
      },
      validarModalEmail () {
        if (this.$refs.emailconfig.validate()) {
          if (this.email_senha === this.confirmar_senha) {
            this.dialog = true
          }
        }
      },
      validarServicoEmail () {
        if (this.$refs.emailteste.validate()) {
          this.configuracao_email.email_senha = this.email_senha
          let params = {}
          params.configuracao_email = this.configuracao_email
          params.email_teste = this.email_teste
          this.$http.post(`/admin/parametrizacao/email/testar/${this.administradora.id_administradora}`, params)
            .then(() => {
              this.dialog = false
              alert(Mensagens.validacaoConfigEmailSucesso)
            })
            .catch(() => {
              this.dialog = false
              error(null, Mensagens.validacaoConfigEmailErro)
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wi-200{
    width: 200px;
  }
  .corpo-div {
    margin-top: 32px;
    margin-right: 120px;
    margin-left: 120px;
  }
  .paddingrl-47-60{
    padding-left: 47px;
    padding-right: 65px;
  }
  .marginlr-40{
    margin-left: 40px;
    margin-right: 40px;
  }
  .v-btn.btn-default:not(.shrink) {
    width: 240px !important;
  }
</style>
