<script>
  import { VForm } from 'vuetify/es5/components/VForm'

  export default {
    name: 'MForm',
    extends: VForm,
    methods: {
      validate () {
        return this.inputs.filter(function (input) {
          input.touche && input.touche()
          return !input.validate(true)
        }).length === 0
      }
    }
  }
</script>
