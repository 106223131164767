<script>
  import { VTextField } from 'vuetify/es5/components/VTextField'

  export default {
    name: 'MTextField',
    extends: VTextField,
    data: () => ({
      touched: false
    }),
    computed: {
      shouldValidate () {
        if (this.externalError) return true
        if (this.isResetting) return false

        // Nota: Se for mexer nos estados do input depure-os, pois alguns comportamentos são duvidosos.
        return this.hasInput ? this.hasFocused && !this.isFocused : (this.hasInput || this.hasFocused) && this.touched
      }
    },
    created () {
      this.$on('blur', this.touch)
    },
    methods: {
      touch () {
        this.touched = true
      }
    }
  }
</script>
