<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container>
    <v-layout>
      <v-flex>
        <v-layout xs4 mb-2>
          <v-flex mt-4>
            <h1>Desativar sistema</h1>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <hr class="solid" />
    <v-layout>
      <v-layout column mt-3 class="card">
        <v-switch
            v-model="sistema_ativo"
            :label="model.descricao">
        </v-switch>
        <v-row class="mt-6" justify="end">
          <v-btn color="primary" class="button btn-default white--text" type="submit" @click="exibeDialog(true)">Salvar</v-btn>
        </v-row>
      </v-layout>
    </v-layout>
    <v-dialog v-model="dialog" width="500" :scrollable="true" content-class="app-content" persistent>
      <v-card style="border-radius: 16px">
        <v-form ref="senha" @submit.prevent="validarSenha">
          <v-card-title class="text-h5">
            <v-flex class="d-flex">
              <v-flex>Confirmação de senha</v-flex>
              <v-menu bottom left>
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" icon @click="exibeDialog(false)">
                    <v-icon>fa fa-times</v-icon>
                  </v-btn>
                </template>
              </v-menu>
            </v-flex>
          </v-card-title>
          <v-card-text>
            <p class="mb-4">{{ descricao_msg_dialog }}</p>
            <label class="label-input">Confirmar senha *</label>
            <m-text-field v-model="senha"
                          placeholder="Senha"
                          :rules="[rules.required]"
                          :type="pass_visible ? 'text' : 'password'"
                          dense
                          outlined
                          validate-on-blur>
              <v-icon slot="append" @click="pass_visible = !pass_visible"
                      :color="pass_visible ? 'primary' : undefined">
                {{ pass_visible ? 'far fa-eye' : 'far fa-eye-slash' }}
              </v-icon>
            </m-text-field>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-flex>
              <v-btn color="primary" class="button btn-default white--text"
                     @click="validarSenha">
                Salvar
              </v-btn>
              <v-btn text color="primary" class="button btn-default white--text"
                     @click="exibeDialog(false)">
                Cancelar
              </v-btn>
            </v-flex>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { alert, error } from '@/plugins/helpers'
  import Rules from '@/plugins/validation'
  import { Mensagens } from '@/mensagens'

  const DescricoesStatus = {
    ATIVO: 'Sistema ativo',
    INATIVO: 'Sistema inativo'
  }

  const MensagensDialog = {
    INATIVAR: 'Tem certeza que deseja desativar o sistema? Enquanto desativado o sistema não enviará e-mail e SMS de solicitação de assinatura e os signatários não conseguirão assinar contratos.',
    ATIVAR: 'Tem certeza que deseja ativar o sistema? Enquanto ativado o sistema enviará e-mails e SMS e permitirá aos signatários assinar contratos'
  }

  export default {
    name: 'DesativarSistema',
    data: () => ({
      model: {
        descricao: DescricoesStatus.ATIVO
      },
      descricao_msg_dialog: MensagensDialog.INATIVAR,
      sistema_ativo: true,
      senha: '',
      pass_visible: false,
      administradora: {},
      dialog: false
    }),
    computed: {
      rules: () => Rules
    },
    watch: {
      sistema_ativo (val) {
        this.alterarDescricaoStatus(val)
      }
    },
    mounted () {
      this.administradora = this.$session.get('administradora')
      this.$http.get(`/admin/parametrizacao/status/${this.administradora.id_administradora}`)
        .then((response) => {
          const isAtivo = response.data.sistema_ativo
          this.sistema_ativo = isAtivo
          this.alterarDescricaoStatus(isAtivo)
        })
    },
    methods: {
      exibeDialog (exibir) {
        this.dialog = exibir
      },
      alterarDescricaoStatus (ativo) {
        this.model.descricao = (ativo) ? DescricoesStatus.ATIVO : DescricoesStatus.INATIVO
        this.descricao_msg_dialog = (ativo) ? MensagensDialog.ATIVAR : MensagensDialog.INATIVAR
      },
      salvar () {
        this.$http.post(`/admin/parametrizacao/status/salvar/${this.administradora.id_administradora}`, { 'sistema_ativo': this.sistema_ativo })
          .then(() => {
            this.exibeDialog(false)
            alert(Mensagens.configuracaoSucesso)
          })
          .catch(() => {
            const msg_ativo_inativo = (this.sistema_ativo) ? 'ativar' : 'desativar'
            error(null, `Falha ao ${msg_ativo_inativo} o sistema. Tente novamente mais tarde.`)
          })
      },
      validarSenha () {
        if (this.$refs.senha.validate()) {
          this.$http.post('/admin/usuario/confirmarsenha', { 'senha': this.senha })
            .then((response) => {
              if (response.data.ok) {
                this.salvar()
              } else {
                error(null, Mensagens.falhaAlteracoesSenha)
              }
            })
            .catch(() => {
              error(null, Mensagens.falhaAlteracoesSenha)
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .wi-200{
    width: 200px;
  }
  .corpo-div {
    margin-top: 32px;
    margin-right: 120px;
    margin-left: 120px;
  }
  .paddingrl-47-60{
    padding-left: 47px;
    padding-right: 65px;
  }
  .marginlr-40{
    margin-left: 40px;
    margin-right: 40px;
  }
  .v-btn.btn-default:not(.shrink) {
    width: 240px !important;
  }
</style>
